import { Injectable } from '@angular/core';
import { AccessControlList } from '../../../models/access-control-list.model';

@Injectable({
  providedIn: 'root',
})
export class BasePermissionsService {
  private permissionObjectList = {
    person: this.person,
    selfieConsolidated: this.selfieConsolidated,
    selfieRecommendationReject: this.selfieRecommendationReject,
    examRequest: this.examRequest,
    referral: this.referral,
    selfieDisease: this.selfieDisease,
    examParse: this.examParse,
    performedExam: this.performedExam,
    formQuestion: this.formQuestion,
    formSubmission: this.formSubmission,
    patientNote: this.patientNote,
    appointment: this.appointment,
    airtableView: this.airtableView,
  };

  get permissions() {
    return this.permissionObjectList;
  }

  get airtableView(): AccessControlList | Record<string, string> {
    return {
      create: 'AIRTABLE_VIEW_CREATE',
      read: 'AIRTABLE_VIEW_READ',
      update: 'AIRTABLE_VIEW_UPDATE',
      generateEmbedUrl: 'AIRTABLE_VIEW_GENERATE_EMBED_URL',
    };
  }

  get person(): AccessControlList {
    return {
      create: 'PERSON_CREATE',
      read: 'PERSON_READ',
      update: 'PERSON_UPDATE',
    };
  }

  get selfieConsolidated(): AccessControlList {
    return {
      read: 'SELFIE_CONSOLIDATED_READ',
    };
  }

  get selfieRecommendationReject(): AccessControlList {
    return {
      create: 'SELFIE_RECOMMENDATION_REJECT_CREATE',
      read: 'SELFIE_RECOMMENDATION_REJECT_READ',
      remove: 'SELFIE_RECOMMENDATION_REJECT_DELETE',
    };
  }

  get examRequest(): AccessControlList | Record<string, string> {
    return {
      create: 'EXAM_REQUEST_CREATE',
      read: 'EXAM_REQUEST_READ',
      remove: 'EXAM_REQUEST_DELETE',
      autoGenerate: 'EXAM_REQUEST_AUTO_GENERATE',
      regenerateDocument: 'EXAM_REQUEST_REGENERATE_DOCUMENT',
    };
  }

  get referral(): AccessControlList | Record<string, string> {
    return {
      read: 'REFERRAL_READ',
      create: 'REFERRAL_CREATE',
      remove: 'REFERRAL_DELETE',
      autoGenerate: 'REFERRAL_AUTO_GENERATE',
      generateNotes: 'REFERRAL_GENERATE_NOTES',
      regenerateDocument: 'REFERRAL_REGENERATE_DOCUMENT',
    };
  }

  get selfieDisease(): AccessControlList {
    return {
      read: 'SELFIE_DISEASE_READ',
    };
  }

  get examParse(): AccessControlList {
    return {
      read: 'EXAM_PARSE_READ',
      update: 'EXAM_PARSE_UPDATE',
    };
  }

  get performedExam(): AccessControlList {
    return {
      create: 'PERFORMED_EXAM_CREATE',
      read: 'PERFORMED_EXAM_READ',
      update: 'PERFORMED_EXAM_UPDATE',
      remove: 'PERFORMED_EXAM_DELETE',
    };
  }

  get formQuestion(): AccessControlList {
    return {
      read: 'FORM_QUESTION_READ',
    };
  }

  get formSubmission(): AccessControlList {
    return {
      create: 'FORM_SUBMISSION_CREATE',
      read: 'FORM_SUBMISSION_READ',
      update: 'FORM_SUBMISSION_UPDATE',
    };
  }

  get patientNote(): AccessControlList {
    return {
      create: 'PATIENT_NOTE_CREATE',
      read: 'PATIENT_NOTE_READ',
    };
  }

  get appointment(): AccessControlList {
    return {
      create: 'APPOINTMENT_CREATE',
      read: 'APPOINTMENT_READ',
      update: 'APPOINTMENT_UPDATE',
      remove: 'APPOINTMENT_DELETE',
    };
  }


}

export type permissionEntity =
  'person' |
  'selfieConsolidated' |
  'selfieRecommendationReject' |
  'examRequest' |
  'referral' |
  'selfieDisease' |
  'examParse' |
  'performedExam' |
  'formQuestion' |
  'formSubmission' |
  'patientNote' |
  'appointment' |
  'airtableView';
