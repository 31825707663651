import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Subscription } from '../models/subscription';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionEntityService extends BaseEntityService<Subscription> {
  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'subscription',
        plural: 'subscriptions',
      },
      routerParamsService
    );
    this.nameId = 'subscriptionId';
  }
}
