<!--<div class="topbar mod">-->
<!--  <div [fxLayoutAlign]="fxLayout" class="content" fxLayout="row">-->
<!--    <div *ngIf="personId">-->
<!--      <a (click)="linkBack()" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="4px"-->
<!--         href="javascript:void(null)">-->
<!--        <mat-icon>chevron_left</mat-icon>-->
<!--        <span>Voltar à lista</span>-->
<!--      </a>-->
<!--    </div>-->
<!--    <div fxFlex></div>-->
<!--    <div class="login" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="20px">-->
<!--      <div>-->
<!--        <div>{{name}}</div>-->
<!--        <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="6px">-->
<!--          <div (click)="jwtAuth.signout()" class="text click">Logout</div>-->
<!--          <div (click)="jwtAuth.signout()" class="click" fxLayout="row" fxLayoutAlign="center center">-->
<!--            <mat-icon class="icon">logout-->
<!--            </mat-icon>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div>-->
<!--        <div class="avatar" fxLayout="row" fxLayoutAlign="center center">-->
<!--          <mat-icon class="icon-avatar">-->
<!--            person_circle-->
<!--          </mat-icon>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
<!--</div>-->


<mat-toolbar class="topbar mat-bg-card">
  <span fxFlex></span>
  <!-- Top left user menu -->
  <button
    [matMenuTriggerFor]="accountMenu"
    class="topbar-button-right img-button"
    mat-icon-button
  >
    <mat-icon>person</mat-icon>
  </button>

  <mat-menu #accountMenu="matMenu">
    <button (click)="jwtAuth.signout()" mat-menu-item>
      <mat-icon>exit_to_app</mat-icon>
      <span>Sair</span>
    </button>
  </mat-menu>
</mat-toolbar>
