<h1 mat-dialog-title>Cadastrar diagnóstico</h1>
<div mat-dialog-content>
  <person-finding-type-autocomplete (callback)="getAutocomplete($event)"></person-finding-type-autocomplete>
  <mat-chip-listbox aria-label="Diagnósticos">
    <mat-chip (removed)="removeChip(findingType)" *ngFor="let findingType of findingTypes">
      <span> {{findingType.name}}</span>
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-listbox>
</div>
<div mat-dialog-actions>
  <div fxFill fxLayout="row">
    <button color="accent"
            fxFlex="50"
            mat-dialog-close
            mat-raised-button
            type="button">
      Cancelar
    </button>
    <button (click)="submit()"
            [disabled]="!checkIsValid() || isLoading"
            color="primary"
            fxFlex="50"
            mat-raised-button
            type="button">
      Salvar
    </button>
  </div>
</div>
