import { Injectable } from '@angular/core';
import { ExamResult } from '../../../state/models/exams';
import { NumeralService } from '../../../shared/services/numeral.service';

@Injectable({
  providedIn: 'root',
})
export class PersonExamService {
  constructor(private numeralService: NumeralService) {}

  formatExamResults(er: ExamResult) {
    let value: any = '';
    let unit = '';
    value = er?.value;
    unit = er?.expectedExamResult?.unit;
    if (er?.expectedExamResult?.valueType === 'NUMBER') {
      if (value) {
        value = parseFloat(value);
        value = this.numeralService.format(value, '0,0.[0000]');
      }
    }

    if (value && unit) {
      return `${value} ${unit}`;
    }

    return value;
  }
}
