import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { noop, Observable, Unsubscribable } from 'rxjs';
import { debounceTime, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../../shared/decorators/auto-unsubscribe.decorator';
import { AppConfirmService } from '../../../../shared/services/app-confirm/app-confirm.service';
import { UrlService } from '../../../../shared/services/url.service';
import { LaboratoryEntityService } from '../../../../state/entity-services/laboratory-entity.service';
import { Laboratory } from '../../../../state/models/laboratory';

@Component({
  selector: 'person-laboratory-autocomplete',
  templateUrl: './person-laboratory-autocomplete.component.html',
  styleUrls: ['./person-laboratory-autocomplete.component.scss'],
})
@AutoUnsubscribe()
export class PersonLaboratoryAutocompleteComponent
  implements OnInit, OnDestroy {
  @CombineSubscriptions()
  subscriptions: Unsubscribable;

  @Output() callback: EventEmitter<Laboratory> = new EventEmitter<Laboratory>();

  searchControl = new FormControl(null, []);

  options: Laboratory[] = [];
  filteredOptions: Observable<Laboratory[]>;
  hasFind: boolean;
  value: string;
  allowRewriteValue = false;

  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

  constructor(
    private router: Router,
    private confirmService: AppConfirmService,
    private route: ActivatedRoute,
    private urlService: UrlService,
    private laboratoryEntityService: LaboratoryEntityService,
  ) {
    this.subscriptions = route.data
      .pipe(
        tap(() => {
          this.filteredOptions = this.searchControl.valueChanges.pipe(
            tap(() => {
              this.hasFind = false;
              this.value = '';
            }),
            debounceTime(300),
            startWith(''),
            map(response => {
              if (typeof response !== 'string') {
                return response?.name || '';
              }
              return response ? response?.trim() : '';
            }),
            filter(value => {
              return typeof value === 'string' && value.length > 0;
            }),
            switchMap(q => {
              this.value = q;
              return this.laboratoryEntityService.getWithQuery({
                q,
              });
            }),
            tap((value: Laboratory[]) => {
              this.hasFind = value.length === 0;
              this.options = value;
              // this.setOption(value);
              return value;
            }),
          );
        }),
      )
      .subscribe(noop);
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  setOption(value: Laboratory[]) {
    if (value && value.length > 0 && value[0].name && this.allowRewriteValue) {
      this._auto.writeValue({ laboratory: value[0] });
      this.allowRewriteValue = false;
    }
  }

  onSelection($event: MatOptionSelectionChange, preSelect?) {
    if ($event.isUserInput) {
      const { laboratory } = $event?.source?.value || preSelect;
      if (laboratory) {
        this.callback.emit(laboratory);
      }
    }
  }

  displayFn(response): string {
    const { laboratory } = response || {};
    if (!laboratory) {
      return '';
    }
    const { name } = laboratory || {};
    return `${ name }`;
  }

  createNewLaboratory() {
    if (this.value) {
      this.laboratoryEntityService
        .createLaboratory(this.value)
        .pipe(
          tap(response => {
            this.callback.emit(response);
            this.searchControl.setValue(response);
            this.allowRewriteValue = true;
          }),
        )
        .subscribe();
    }
  }

  getPosts(value: any) {
  }
}
