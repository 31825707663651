import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { DateService } from '../../../../shared/services/date.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { noop, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { SelfieRecommendationRejectsEntityService } from '../../../../state/entity-services/selfie-recommendation-rejects-entity.service';
import { ExamRequest, Exams } from '../../../../state/models/exams';
import { FindingType } from '../../../../state/models/finding';
import { Translation } from '../../services/person-risks.service';

@Component({
  selector: 'person-selfie-recommendation-reject-dialog',
  templateUrl: './person-selfie-recommendation-reject-dialog.component.html',
})
export class PersonSelfieRecommendationRejectDialogComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  formConfig: any;
  values: any;
  isLoading: boolean;
  selfieRecommendation: any;
  chips: Exams[] = [];
  public translations: Translation[] = [];
  private loading: boolean;

  constructor(
    private dateService: DateService,
    private utilsService: UtilsService,
    private selfieRecommendationRejectsEntityService: SelfieRecommendationRejectsEntityService,
    private dialogRef: MatDialogRef<PersonSelfieRecommendationRejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.selfieRecommendation = this.data.selfieRecommendation;
    this.translations = this.data.translations;
    this.setExam();
    this.setForm();
  }

  ngOnInit(): void {}

  public checkIsValid(): boolean {
    return this.formConfig.form.status === 'VALID';
  }

  submit() {
    const { reason } = this.values;
    this.setLoading(true);
    this.selfieRecommendationRejectsEntityService
      .save(
        this.chips,
        this.data.selfieRecommendation,
        this.data.selfieConsolidatedId,
        reason,
      )
      .pipe(
        tap(() => {
          this.setLoading(false);
          this.dialogRef.close(true);
        }),
        catchError(err => {
          this.setLoading(false);
          const { message } = err || {};
          this.utilsService.toast(message || 'Erro desconhecido da api', 'Ok');
          throw err;
        }),
      )
      .subscribe(noop);
  }

  setForm() {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};
    this.formConfig = {
      form,
      options,
      fields: [
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'column',
          },
          fieldGroup: [
            {
              type: 'textarea',
              key: 'reason',
              defaultValue: '',
              props: {
                required: true,
                rows: 3,
                placeholder: 'Razão da rejeição',
              },
            },
          ],
        },
      ],
    };
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (this.progressBar) {
      this.progressBar.mode = loading ? 'indeterminate' : 'determinate';
    }
  }

  update(update: any) {
    this.values = update;
  }

  getAutocomplete(chip: any) {
    if (this.selfieRecommendation.type === 'EXAM') {
      if (chip && !this.chips.some(ft => ft.id === chip.id)) {
        this.chips.push(chip);
      }
    }

    if (this.selfieRecommendation.type === 'REFERRAL') {
      if (chip && !this.chips.some(ft => ft.id === chip.id)) {
        this.chips.push(chip);
      }
    }

    this.checkIsValid();
  }

  removeChip(findingType: FindingType) {
    this.chips = this.chips.filter(ft => ft.id !== findingType.id);
  }

  setExam() {
    this.selfieRecommendation.selfieRecommendationExams.forEach(
      selfieRecommendationExam => {
        this.chips.push(selfieRecommendationExam.exam);
      },
    );

    this.selfieRecommendation.selfieRecommendationSpecialties.forEach(
      selfieRecommendationSpecialtie => {
        this.chips.push(selfieRecommendationSpecialtie.specialty);
      },
    );
  }
}
