import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { Referral } from '../models/referral';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BasePermissionsService } from '../entity-management/services/base/base-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class ReferralEntityService extends BaseEntityService<Referral> {
  constructor(
    basePermissionsService: BasePermissionsService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'referral',
        plural: 'referrals',
      },
      routerParamsService,
      basePermissionsService.referral,
    );
    this.nameId = 'referralId';
  }

  autoGenerate(selfieConsolidatedId: number): Observable<any> {
    this.validatePermission('autoGenerate', true);
    const body = { selfieConsolidated: { id: selfieConsolidatedId } };
    return this.httpClient
      .post(`${ this.entityUrl }/auto-generate`, body)
      .pipe(map((response: any) => response?.autoGenerate));
  }

  generateNotes(referral: Referral): Observable<string> {
    this.validatePermission('generateNotes', true);
    const body = { referral };
    return this.httpClient
      .post(`${ this.entityUrl }/generate-notes`, body)
      .pipe(map((response: any) => response?.referral.lastMedicalDocument?.notes));
  }


  getDocumentUrl(id: any): Observable<Referral> {
    this.validatePermission('regenerateDocument', true);
    return this.httpClient.post(`${ this.entityUrl }/${ id }/get-document-url`, {}).pipe(
      map(response => {
        return response['referral'] as Referral;
      }),
    );
  }
}
