<div class="sidenav-hold" fxLayout="column">
  <div fxFlex="100">
    <br />
    <div componentName="personHeader" dynamicComponentCreate></div>
  </div>
  <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
  <div>
    <div *ngIf="hasIconTypeMenuItem" class="icon-menu mb-16">
      <!-- Icon menu separator -->
      <div class="mb-16 nav-item-sep">
        <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
        <span class="text-muted icon-menu-title">{{iconTypeMenuTitle}}</span>
      </div>
      <!-- Icon menu items -->
      <div *ngFor="let item of menuItems" class="icon-menu-item">
        <button *ngIf="!item.disabled && item.type === 'icon'" [matTooltip]="item.tooltip"
                mat-raised-button
                routerLink="/{{item.state}}"
                routerLinkActive="mat-bg-primary">
          <mat-icon *ngIf="item.icon" class="sidenav-mat-icon material-icons-outlined">{{item.icon}}</mat-icon>
        </button>
      </div>
    </div>
    <ul appDropdown class="sidenav">
      <li *ngFor="let item of menuItems" appDropdownLink routerLinkActive="open">
        <!-- separator -->
        <div *ngIf="item.type === 'separator'" class="nav-item-sep">
          <mat-divider></mat-divider>
          <span class="text-muted">{{item.name}}</span>
        </div>

        <!-- Item -->
        <div *ngIf="!item.disabled && item.type !== 'separator' && item.type !== 'icon'" class="lvl1">
          <a *ngIf="item.type === 'link'" [fragment]="item.fragment" [routerLink]="'/'+item.state" appDropdownToggle
             matRipple>
            <mat-icon *ngIf="item.icon" class="sidenav-mat-icon material-icons-outlined ">{{item.icon}}</mat-icon>
            <span class="item-name lvl1">{{item.name}}</span>
            <span fxFlex></span>
            <span *ngFor="let badge of item.badges" [ngStyle]="{background: badge.color}"
                  class="menuitem-badge mat-bg-{{ badge.color }}">{{ badge.value }}</span>
          </a>
          <a *ngIf="item.type === 'extLink'" [href]="item.state" appDropdownToggle matRipple target="_blank">
            <mat-icon *ngIf="item.icon" class="sidenav-mat-icon material-icons-outlined">{{item.icon}}</mat-icon>
            <span class="item-name lvl1">{{item.name}}</span>
            <span fxFlex></span>
            <span *ngFor="let badge of item.badges" [ngStyle]="{background: badge.color}"
                  class="menuitem-badge mat-bg-{{ badge.color }}">{{ badge.value }}</span>
          </a>

          <!-- DropDown -->
          <a *ngIf="item.type === 'dropDown'" appDropdownToggle matRipple>
            <mat-icon *ngIf="item.icon" class="sidenav-mat-icon material-icons-outlined">{{item.icon}}</mat-icon>
            <span class="item-name lvl1">{{item.name}}</span>
            <span fxFlex></span>
            <span *ngFor="let badge of item.badges" [ngStyle]="{background: badge.color}"
                  class="menuitem-badge mat-bg-{{ badge.color }}">{{ badge.value }}</span>
            <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
          </a>
          <!-- LEVEL 2 -->
          <ul *ngIf="item.type === 'dropDown'" appDropdown class="submenu lvl2">
            <li *ngFor="let itemLvL2 of item.sub" appDropdownLink routerLinkActive="open">
              <a *ngIf="itemLvL2.type !== 'dropDown'"
                 [skipLocationChange]="true"
                 appDropdownToggle
                 fragment="{{ itemLvL2.fragment }}"
                 matRipple
                 routerLink="{{item.state ? '/'+item.state : ''}}/{{itemLvL2.state}}">
                <span class="item-name lvl2">{{itemLvL2.name}}</span>
                <span fxFlex></span>
              </a>

              <a *ngIf="itemLvL2.type === 'dropDown'" appDropdownToggle matRipple>
                <span class="item-name lvl2">{{itemLvL2.name}}</span>
                <span fxFlex></span>
                <mat-icon class="menu-caret">keyboard_arrow_right</mat-icon>
              </a>

              <!-- LEVEL 3 -->
              <ul *ngIf="itemLvL2.type === 'dropDown'" appDropdown class="submenu lvl3">
                <li *ngFor="let itemLvL3 of itemLvL2.sub" appDropdownLink routerLinkActive="open">
                  <a
                    [fragment]="item.fragment"
                    appDropdownToggle
                    matRipple
                    routerLink="{{item.state ? '/'+item.state : ''}}{{itemLvL2.state ? '/'+itemLvL2.state : ''}}/{{itemLvL3.state}}">
                    <span class="item-name lvl3">{{itemLvL3.name}}</span>
                  </a>
                </li>
              </ul>

            </li>
          </ul>
        </div>
      </li>
      <li>
        <mat-divider [ngStyle]="{margin: '0 -24px'}"></mat-divider>
        <a (click)="logout()" matRipple>
          <mat-icon class="sidenav-mat-icon material-icons-outlined ">logout</mat-icon>
          <span class="item-name lvl1">Sair ({{ jwtAuth.getUser()?.personProperties?.name }})</span>
        </a>
      </li>
      <li style="">
        <spa fxLayout="row" fxLayoutAlign="center center">
          <span class="item-name lvl1" style="font-size: 10px;">Versão: ({{ version }})</span>
        </spa>
      </li>
    </ul>
  </div>
</div>
