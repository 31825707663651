<h1 mat-dialog-title>Rejeitar
  recomendação: {{ selfieRecommendation.type | translation: 'selfieRecommendation.type' : translations}}</h1>
<div mat-dialog-content>
  <div *ngIf="selfieRecommendation && selfieRecommendation.type === 'EXAM'">
    <person-exams-autocomplete
      (callback)="getAutocomplete($event)"
      [clearForm]="true"></person-exams-autocomplete>
  </div>
  <div *ngIf="selfieRecommendation && selfieRecommendation.type === 'REFERRAL'">
    <person-specialties-autocomplete
      (callback)="getAutocomplete($event)"
      [clearForm]="true"></person-specialties-autocomplete>
  </div>

  <mat-chip-listbox aria-label="Exames">
    <mat-chip (removed)="removeChip(chip)" *ngFor="let chip of chips">
      <span> {{chip.name}}</span>
      <button matChipRemove>
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip>
  </mat-chip-listbox>
  <br />


  <formly-form
    (modelChange)="update($event)"
    [fields]="formConfig.fields"
    [form]="formConfig.form"
    [model]="values"
    [options]="formConfig.options"
  ></formly-form>
</div>
<div mat-dialog-actions>
  <div fxFill fxLayout="row">
    <button color="accent"
            fxFlex="50"
            mat-dialog-close
            mat-raised-button
            type="button">
      Cancelar
    </button>
    <button (click)="submit()"
            [disabled]="!checkIsValid() || isLoading"
            color="primary"
            fxFlex="50"
            mat-raised-button
            type="button">
      Salvar
    </button>
  </div>
</div>
