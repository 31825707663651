import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Laboratory } from '../models/laboratory';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RouterParamsService } from '../../shared/services/router-params.service';

@Injectable({
  providedIn: 'root',
})
export class LaboratoryEntityService extends BaseEntityService<Laboratory> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'laboratory', plural: 'laboratories', url: 'laboratories' },
      routerParamsService
    );
    this.nameId = 'laboratoryId';
  }

  createLaboratory(name: string): Observable<Laboratory> {
    const body: Laboratory = {
      name,
    };
    return this.add(body);
  }
}
