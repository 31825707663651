import { Observable } from 'rxjs';
import { MatDialogRef, MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';

import { AppComfirmComponent } from './app-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class AppConfirmService {
  constructor(private dialog: MatDialog) {}

  public confirm(data?: DataConfirm): Observable<boolean> {
    const nData = {
      title: data?.title || 'Confirmar',
      message: data?.message || 'Você tem certeza?',
      buttonConfirm: data?.buttonConfirm ?? {
        label: 'Ok',
        show: true
      },
      buttonCancel: data?.buttonCancel || {
        label: 'Cancelar',
        show: true
      }
    };
    let dialogRef: MatDialogRef<AppComfirmComponent>;
    dialogRef = this.dialog.open( AppComfirmComponent, {
      width: '500px',
      disableClose: data?.disableClose ?? true,
      data: nData
    } );
    return dialogRef.afterClosed();
  }
}

export interface DataConfirm {
  disableClose?: boolean;
  title: string;
  message?: string;
  buttonConfirm?: {
    show: boolean;
    label: string;
  };
  buttonCancel?: {
    show: boolean;
    label: string;
  };
}
