import { Injectable } from '@angular/core';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Finding, FindingType } from '../models/finding';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FindingEntityService extends BaseEntityService<Finding> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'finding', plural: 'findings' },
      routerParamsService
    );
    this.nameId = 'findingId';
  }

  getWithQueryType(
    queryParams: any,
  ): Observable<FindingType[]> {
    const params = queryParams;
    return this.httpClient
      .get(`${ environment.apiUrl }/finding-types`, { params })
      .pipe(
        map((response: any) => response?.findingTypes || []),
      );
  }
}
