<form class="autocomplete">
  <mat-form-field appearance="outline" class="width-100">
    <mat-label>Especialidades</mat-label>
    <input [formControl]="searchControl"
           [matAutocomplete]="auto"
           aria-label="Especialidades"
           matInput
           placeholder="Digite uma especialidade:"
           type="text">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" autoActiveFirstOption>
      <mat-option (onSelectionChange)="onSelection($event)"
                  *ngFor="let option of filteredOptions | async"
                  [value]="{specialty: option}">
        {{option?.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="!searchControl.hasError('required')">
      CPF inválido
    </mat-error>
  </mat-form-field>
</form>
