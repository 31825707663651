import { Injectable } from '@angular/core';
import { LocalStoreService } from '../local-store.service';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { User } from '../../../state/models/user.model';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { TokenEntityService } from 'app/state/entity-services/token-entity.service';
import { Token } from 'app/state/models/token';
import { PersonEntityService } from '../../../state/entity-services/person-entity.service';
import { Person } from '../../../state/models/person';
import * as Sentry from '@sentry/angular';
import { EntityManagementService } from '../../../state/entity-management/services/base/entity-management.service';

@Injectable({
  providedIn: 'root',
})
export class JwtAuthService {
  token;
  isAuthenticated: boolean;
  user: User = undefined;
  user$ = new BehaviorSubject<User>(this.user);
  signingIn: boolean;
  return: string;
  JWT_TOKEN = 'JWT_TOKEN';
  JWT_REFRESH_TOKEN = 'JWT_REFRESH_TOKEN';
  APP_USER = 'EGRET_USER';

  constructor(
    private entityManagementService: EntityManagementService,
    private tokenEntityService: TokenEntityService,
    private personEntityService: PersonEntityService,
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.route.queryParams.subscribe(
      params => (this.return = params['return'] || '/'),
    );
  }

  public signIn(body): Observable<Person> {
    this.signingIn = true;
    return this.auth(body).pipe(
      tap((res: AuthResponse) => {
        const { accessToken, refreshToken } = res;
        this.setUserAndToken({ accessToken, refreshToken });
      }),
      mergeMap((res: any) =>
        this.getSelf().pipe(
          map(user => {
            const tokens = {
              accessToken: res.accessToken,
              refreshToken: res.refreshToken,
            };
            return Object.assign({}, { tokens, user });
          }),
        ),
      ),
      map((res: any) => {
        this.setUserAndToken(res.tokens, res.user, !!res);
        this.signingIn = false;
        return res;
      }),
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    return this.getSelf().pipe(
      map((profile: User) => {
        this.setUserAndToken(this.getTokens(), profile, true);
        return profile;
      }),
      catchError(error => {
        this.signout();
        return of(error);
      }),
    );
  }

  public signout() {
    this.entityManagementService.clearAllCache();
    this.setUserAndToken(null, null, false);
    this.router.navigateByUrl('sessions/login');
  }

  isLoggedIn(): boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.JWT_TOKEN);
  }

  getTokens() {
    return {
      accessToken: this.ls.getItem(this.JWT_TOKEN),
      refreshToken: this.getJwtRefreshToken(),
    };
  }

  getJwtRefreshToken() {
    return this.ls.getItem(this.JWT_REFRESH_TOKEN);
  }

  getUser(): Person {
    return this.ls.getItem(this.APP_USER) as Person;
  }

  setUserAndToken(tokens: AuthResponse, user?: User, isAuthenticated = false) {
    this.isAuthenticated = isAuthenticated;
    const { accessToken, refreshToken } = tokens || {};
    this.token = accessToken;
    if (user || user === null) {
      this.user = user;
      this.user$.next(user);
      this.ls.setItem(this.APP_USER, user);
    }
    this.ls.setItem(this.JWT_TOKEN, accessToken);
    this.ls.setItem(this.JWT_REFRESH_TOKEN, refreshToken);
    this.setUserSentry(true);
  }

  refreshToken() {
    const body = {
      grantType: 'REFRESH_TOKEN',
      refreshToken: this.getJwtRefreshToken(),
    };
    return this.signIn(body).pipe(
      catchError(error => {
        return throwError(error);
      }),
    );
  }

  private auth(token: string): Observable<Token> {
    return this.tokenEntityService.getTokenGeneral(token);
  }

  private getSelf(): Observable<User> {
    return this.personEntityService.getSelf();
  }

  setUserSentry(force?: boolean): void {
    const { id, email, phone, document } = this.getUser() || {};
    if (!document && force === undefined) {
      return;
    }

    Sentry.setUser(
      {
        id,
        email,
        phone,
        document,
      },
    );
  }
}

interface AuthResponse {
  // The token
  accessToken: string;
  refreshToken: string;
}
