import { Component, Inject, OnInit } from '@angular/core';
import { Person } from '../../../../state/models/person';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { FindingEntityService } from '../../../../state/entity-services/finding-entity.service';
import { forkJoin, noop, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError, finalize } from 'rxjs/operators';
import { UtilsService } from '../../../../shared/services/utils.service';
import { Finding, FindingType } from '../../../../state/models/finding';

@Component({
  selector: 'person-finding-form-dialog',
  templateUrl: './person-finding-form-dialog.component.html',
})
export class PersonFindingFormDialogComponent implements OnInit {
  formConfig: any;
  values: any;
  public person: Person = this.personEntityService.lastPersonWithAge;
  findingTypes: FindingType[] = [];
  isLoading: boolean;

  constructor(
    private utilsService: UtilsService,
    private dialogRef: MatDialogRef<PersonFindingFormDialogComponent>,
    private findingEntityService: FindingEntityService,
    private personEntityService: PersonEntityService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const { person } = this.data;
    this.person = person;
  }

  ngOnInit(): void {}

  submit() {
    if (this.checkIsValid()) {
      const observableFinding = this.findingTypes.map(findingType => {
        const body: Finding = {
          person: { id: this.person.id },
          findingType,
        };
        return this.findingEntityService.add(body);
      });
      this.isLoading = true;
      forkJoin(observableFinding)
        .pipe(
          tap(() => {
            this.dialogRef.close(true);
          }),
          finalize(() => (this.isLoading = false)),
          catchError(error => {
            this.utilsService.toast('Erro ao criar encaminhamento', 'Ok');
            throw error;
          }),
        )
        .subscribe(noop);
    }
  }

  public checkIsValid(): boolean {
    return this.findingTypes.length > 0 && !!this.person;
  }

  getAutocomplete(findingType: FindingType) {
    if (
      findingType &&
      !this.findingTypes.some(ft => ft.id === findingType.id)
    ) {
      this.findingTypes.push(findingType);
    }
  }

  removeChip(findingType: FindingType) {
    this.findingTypes = this.findingTypes.filter(
      ft => ft.id !== findingType.id,
    );
  }
}
