<div [dir]="layoutConf?.dir" class="app-admin-wrap">
  <!-- Header for top navigation layout -->
  <!-- ONLY REQUIRED FOR **TOP** NAVIGATION LAYOUT -->
  <app-header-top
    *ngIf="layoutConf.navigationPos === 'top'"
    [notificPanel]="notificationPanel"
  >
  </app-header-top>
  <!-- Main Container -->
  <mat-sidenav-container
    [class.top]="layoutConf.navigationPos === 'top'"
    [dir]="layoutConf.dir"
    [ngClass]="adminContainerClasses"
    class="app-admin-container app-side-nav-container mat-drawer-transition sidebar-{{layoutConf?.sidebarColor}} topbar-{{layoutConf?.topbarColor}} footer-{{layoutConf?.footerColor}}"
  >
    <mat-sidenav-content>
      <!-- SIDEBAR -->
      <!-- ONLY REQUIRED FOR **SIDE** NAVIGATION LAYOUT -->
      <app-sidebar-side
        (mouseenter)="sidebarMouseenter($event)"
        (mouseleave)="sidebarMouseleave($event)"
        *ngIf="layoutConf.navigationPos === 'side'"
      ></app-sidebar-side>

      <!-- Top navigation layout (navigation for mobile screen) -->
      <!-- ONLY REQUIRED FOR **TOP** NAVIGATION MOBILE LAYOUT -->
      <app-sidebar-top
        *ngIf="layoutConf.navigationPos === 'top' && layoutConf.isMobile"
      ></app-sidebar-top>

      <!-- App content -->
      <div
        class="main-content-wrap"
        id="main-content-wrap"
      >

        <div
          class="rightside-content-hold"
          id="rightside-content-hold"
        >
          <!-- View Loader -->
          <div
            *ngIf="isModuleLoading"
            class="view-loader"
            fxLayout="column"
            fxLayoutAlign="center center"
            style="position: fixed"
          >
            <div class="spinner">
              <div class="double-bounce1 mat-bg-accent"></div>
              <div class="double-bounce2 mat-bg-primary"></div>
            </div>
          </div>
          <!-- Breadcrumb -->
          <app-breadcrumb></app-breadcrumb>
          <!-- View outlet -->
          <div class="container-dynamic">
            <router-outlet></router-outlet>
          </div>

        </div>
        <span *ngIf="layoutConf.footerFixed" class="m-auto"></span>
        <app-footer *ngIf="layoutConf.footerFixed"></app-footer>
      </div>
      <!-- View overlay for mobile navigation -->
      <div
        (click)="closeSidebar()"
        [ngClass]="{'visible': layoutConf.sidebarStyle !== 'closed' && layoutConf.isMobile}"
        class="sidebar-backdrop"
      ></div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
