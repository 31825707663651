import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({ name: 'stringFormat' })
export class StringFormatPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {
  }

  transform(value: any = '', bol?: boolean, breakLines?: boolean): string {
    if (breakLines) {
      return value ? value.replace(/\r?\n/g, '<br />') : value;
    }


    if (bol) {
      return value ? 'Sim' : 'Não';
    }
    if (typeof value === 'string') {
      return this.utilsService.stringFormat(value);
    }
  }
}
