import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { PersonReferralFormService } from '../../services/person-form/person-referral-form.service';
import { Specialty } from '../../../../state/models/specialty';
import { Person } from '../../../../state/models/person';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { ReferralEntityService } from '../../../../state/entity-services/referral-entity.service';
import { noop, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';
import { UtilsService } from '../../../../shared/services/utils.service';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { DateService } from '../../../../shared/services/date.service';
import { PersonDialogsService } from '../../services/person-dialogs.service';
import { Disease } from '../../../../state/models/previous-diseases';
import { FindingType } from '../../../../state/models/finding';
import { TranslationService } from '../../../../shared/services/translation.service';

@Component({
  selector: 'person-referral-form-dialog',
  templateUrl: './person-referral-form-dialog.component.html',
})
export class PersonReferralFormDialogComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  formConfig: any;
  values: any;
  public person: Person = this.personEntityService.lastPersonWithAge;
  loading: boolean;
  chips: Disease[] = [];
  options: any;

  constructor(
    private translationsService: TranslationService,
    private personDialogsService: PersonDialogsService,
    private dateService: DateService,
    private utilsService: UtilsService,
    private referralEntityService: ReferralEntityService,
    private personEntityService: PersonEntityService,
    public personReferralFormService: PersonReferralFormService,
    private dialogRef: MatDialogRef<PersonReferralFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const { person } = this.data;
    this.formConfig = personReferralFormService.getFormReferral();
    this.values = { person: { id: person.id } };

    this.translationsService.getTranslations({ fields: 'SelfieDisease.name' }).subscribe({
      next: response => {
        const [options] = response;
        this.options = options.values as any;
        this.setForm();
      },
    });

  }

  ngOnInit(): void {

  }

  setForm() {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};
    this.formConfig = {
      form,
      options,
      fields: [
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'column',
          },
          fieldGroup: [
            {
              key: 'originatedBy',
              type: 'select',
              defaultValue: '',
              props: {
                label: 'Doenças',
                required: true,
                multiple: true,
                options: Object.keys(this.options).map(key => {
                  return { label: this.options[key], value: key };
                }),
              },
            },
          ],
        },
      ],
    };
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (this.progressBar) {
      this.progressBar.mode = loading ? 'indeterminate' : 'determinate';
    }
  }

  submit() {
    if (this.checkIsValid()) {
      this.setLoading(true);
      const values = {
        ...this.values
      };
      this.referralEntityService
        .add(values)
        .pipe(
          tap(() => {
            this.setLoading(false);
            this.dialogRef.close(true);
          }),
          catchError(err => {
            this.setLoading(false);
            const { message, error } = err || {};
            if (error?.code === 56000) {
              this.personDialogsService.openBirdIdDialog();
            }
            this.utilsService.toast(
              message || 'Erro desconhecido da api',
              'Ok',
            );
            throw error;
          }),
        )
        .subscribe(noop);
    }
  }

  public getSpecialty(specialty: Specialty): void {
    if (specialty && typeof specialty !== 'string') {
      this.values = { ...this.values, specialty: { id: specialty.id } };
    }
  }

  public checkIsValid(): boolean {
    if (!this.values) {
      return false;
    }

    const { specialty, person } = this.values;


    if (!specialty) {
      return false;
    }

    if (!person) {
      return false;
    }

    return this.formConfig?.form?.status !== 'INVALID';
  }

  update(update: any) {
    this.values = { ...this.values, update };
    console.log(this.values);
  }

  getDisiase(chip: Disease) {
    if (chip && !this.chips.some(ft => ft.id === chip.id)) {
      this.chips.push(chip);
    }
    this.checkIsValid();
  }

  removeChip(findingType: FindingType) {
    this.chips = this.chips.filter(ft => ft.id !== findingType.id);
  }
}
