import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { DataConfirm } from './app-confirm.service';

@Component({
  selector: 'app-confirm',
  template: `<h1 matDialogTitle class="mb-8">{{ data.title }}</h1>
    <div mat-dialog-content class="mb-16" [innerHTML]="data.message"></div>
    <div mat-dialog-actions class="pb-16">
      <div fxFill fxLayout="row">
        <button
          color="accent"
          fxFlex="100"
          mat-dialog-close
          mat-raised-button
          type="button"
          *ngIf="data.buttonCancel.show"
          (click)="dialogRef.close(false)"
        >
          {{ data.buttonCancel.label }}
        </button>
        <button
          fxFlex="100"
          mat-raised-button
          type="submit"
          color="primary"
          *ngIf="data.buttonConfirm.show"
          (click)="dialogRef.close(true)"
        >
          {{ data.buttonConfirm.label }}
        </button>
      </div>
    </div>`,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DataConfirm,
  ) {}
}
