import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import {
  PersonReferralFormDialogComponent,
} from '../dialogs/person-referal-form-dialog/person-referral-form-dialog.component';
import {
  PersonFindingFormDialogComponent,
} from '../dialogs/person-finding-form-dialog/person-finding-form-dialog.component';
import {
  PersonFormQuestionFormDialogComponent,
} from '../dialogs/person-form-question-form-dialog/person-form-question-form-dialog.component';
import { FormQuestion, FormSubmission } from '../../../state/models/form.model';
import {
  PersonPerformExamFormDialogComponent,
} from '../dialogs/person-perform-exam-form-dialog/person-perform-exam-form-dialog.component';
import { ExamParses, ExamRequest, PerformedExam } from '../../../state/models/exams';
import {
  PersonExamRequestFormDialogComponent,
} from '../dialogs/person-exam-request-form-dialog/person-exam-request-form-dialog.component';
import {
  PersonExamRequestDialogComponent,
} from '../dialogs/person-exam-request-dialog/person-exam-request-dialog.component';
import { Appointment } from '../../../state/models/appointment';
import {
  PersonAppointmentsFormDialogComponent,
} from '../dialogs/person-appointments-form-dialog/person-appointments-form-dialog.component';
import { Person } from '../../../state/models/person';
import { PersonEditDialogComponent } from '../dialogs/person-edit-dialog/person-edit-dialog.component';
import {
  PersonPerformedExamAudsDialogComponent,
} from '../dialogs/person-performed-exam-auds-dialog/person-performed-exam-auds-dialog.component';
import { PersonFilesDialogComponent } from '../dialogs/person-files-dialog/person-files-dialog.component';
import {
  PersonSelfieRecommendationRejectDialogComponent,
} from '../dialogs/person-selfie-recommendation-reject-dialog/person-selfie-recommendation-reject-dialog.component';
import { SelfieRecommendation } from '../../../state/models/selfie';
import { Translation } from './person-risks.service';
import { PatientNote } from '../../../state/models/patient-note';
import { PersonNotesFormDialogComponent } from '../dialogs/person-notes-form-dialog/person-notes-form-dialog.component';
import {
  PersonMedicalDocumentsFormComponent,
} from '../dialogs/person-medical-documents-form/person-medical-documents-form.component';

@Injectable({
  providedIn: 'root',
})
export class PersonDialogsService {
  constructor(private dialog: MatDialog) {
  }

  public openPersonFile(
    person: Person,
    translationOptions: Record<string, string>,
    entity: any,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonFilesDialogComponent>;
    dialogRef = this.dialog.open(PersonFilesDialogComponent, {
      minWidth: '512px',
      data: { person, translationOptions, entity },
    });
    return dialogRef.afterClosed();
  }

  public rejectAllRecommendations(
    selfieRecommendation: SelfieRecommendation,
    selfieConsolidatedId: number,
    translations: Translation[],
  ): Observable<string> {
    let dialogRef: MatDialogRef<PersonSelfieRecommendationRejectDialogComponent>;
    dialogRef = this.dialog.open(
      PersonSelfieRecommendationRejectDialogComponent,
      {
        width: '1024px',
        data: { selfieRecommendation, selfieConsolidatedId, translations },
      },
    );
    return dialogRef.afterClosed();
  }

  public openPersonEdit(person: Person): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonEditDialogComponent>;
    dialogRef = this.dialog.open(PersonEditDialogComponent, {
      width: '1024px',
      data: person,
    });
    return dialogRef.afterClosed();
  }

  public openPerformedExamAuds(person: Person): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonPerformedExamAudsDialogComponent>;
    dialogRef = this.dialog.open(PersonPerformedExamAudsDialogComponent, {
      minWidth: '1024px',
      data: person,
    });
    return dialogRef.afterClosed();
  }

  public openPerformedExam(performedExam: PerformedExam): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonExamRequestDialogComponent>;
    dialogRef = this.dialog.open(PersonExamRequestDialogComponent, {
      minWidth: '1024px',
      data: performedExam,
    });
    return dialogRef.afterClosed();
  }

  public openFormExamRequest(
    examRequest: ExamRequest,
    person: Person,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonExamRequestFormDialogComponent>;
    dialogRef = this.dialog.open(PersonExamRequestFormDialogComponent, {
      minWidth: '512px',
      data: { examRequest, person },
    });
    return dialogRef.afterClosed();
  }

  public openFormAppointment(
    appointment: Appointment,
    person: Person,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonAppointmentsFormDialogComponent>;
    dialogRef = this.dialog.open(PersonAppointmentsFormDialogComponent, {
      minWidth: '512px',
      data: { appointment, person },
    });
    return dialogRef.afterClosed();
  }

  public openFormNotes(
    patientNote: PatientNote,
    person: Person,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonNotesFormDialogComponent>;
    dialogRef = this.dialog.open(PersonNotesFormDialogComponent, {
      minWidth: '512px',
      data: { patientNote, person },
    });
    return dialogRef.afterClosed();
  }

  public openFormPerformExam(
    performedExam: PerformedExam | PerformedExam[],
    person: Person,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonPerformExamFormDialogComponent>;
    dialogRef = this.dialog.open(PersonPerformExamFormDialogComponent, {
      width: '90vw',
      maxHeight: '90vh',
      maxWidth: '90vw',
      data: { performedExam, person },
      disableClose: true,
    });
    return dialogRef.afterClosed();
  }

  public openCreateReferral(person: Person): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonReferralFormDialogComponent>;
    dialogRef = this.dialog.open(PersonReferralFormDialogComponent, {
      minWidth: '512px',
      data: { person },
    });
    return dialogRef.afterClosed();
  }

  public openCreateFinding(): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonFindingFormDialogComponent>;
    dialogRef = this.dialog.open(PersonFindingFormDialogComponent, {
      minWidth: '512px',
    });
    return dialogRef.afterClosed();
  }

  public openFormQuestion(
    formQuestion: FormQuestion,
    formSubmission: FormSubmission,
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonFormQuestionFormDialogComponent>;
    dialogRef = this.dialog.open(PersonFormQuestionFormDialogComponent, {
      minWidth: '512px',
      data: { formQuestion, formSubmission },
    });
    return dialogRef.afterClosed();
  }

  public openBirdIdDialog(): Observable<boolean> {
    let dialogRef: MatDialogRef<PersonMedicalDocumentsFormComponent>;
    dialogRef = this.dialog.open(PersonMedicalDocumentsFormComponent, {
      minWidth: '512px',
    });
    return dialogRef.afterClosed();
  }
}
