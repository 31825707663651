import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PersonFile } from '../models/person-files';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RouterParamsService } from '../../shared/services/router-params.service';

@Injectable({
  providedIn: 'root',
})
export class PersonFilesEntityService extends BaseEntityService<PersonFile> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'personFile', plural: 'personFiles', url: 'person-files' },
      routerParamsService
    );
    this.nameId = 'personFileId';
  }

  save(values: any): Observable<PersonFile> {
    Object.keys(values).forEach(key => {
      if (!values[key]) {
        delete values[key];
      }
    });
    const { id } = values;
    if (id) {
      return this.update(values);
    }
    return this.add(values);
  }
}
