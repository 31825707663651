import { Injectable } from '@angular/core';
import { ExpectedExamResults } from '../../../../state/models/exams';

@Injectable({
  providedIn: 'root',
})
export class PersonPerformedExamFormService {
  constructor() {}

  getInputs(expectedExamResults: ExpectedExamResults[]) {
    return expectedExamResults.map(er => {
      return this.getOptionForm(er);
    });
  }

  getOptionForm(expectedExamResult: ExpectedExamResults) {
    const { valueType, name: key, unit, options } = expectedExamResult;
    if (valueType === 'CHOICES') {
      return {
        type: 'multicheckbox',
        key,
        templateOptions: {
          options: options.map(opt => {
            return {
              key: opt,
              value: opt,
              label: opt,
            };
          }),
        },
      };
    }

    if (valueType === 'CHOICE') {
      const optionsV = options.map(opt => {
        return {
          value: opt,
          label: opt,
        };
      });

      optionsV.push({
        value: null,
        label: 'Não preencher',
      });

      return {
        type: 'select',
        key,
        defaultValue: '',
        props: {
          label: key,
          class: 'radio-button-form',
          options: optionsV,
        },
      };
    }

    if (valueType === 'NUMBER') {
      return {
        type: 'masked',
        key,
        defaultValue: '',
        props: {
          addonRight: unit,
          mask: 'NUMBER',
          label: key,
        },
      };
    }

    if (valueType === 'TEXT') {
      return {
        type: 'input',
        key,
        defaultValue: '',
        props: {
          label: key,
          addonRight: {
            text: unit,
          },
        },
      };
    }
  }
}
