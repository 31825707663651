<div mat-dialog-content>
  <person-performed-exams></person-performed-exams>
</div>
<div mat-dialog-actions>
  <div fxFill fxLayout="row">
    <button (click)="close()"
            color="accent"
            fxFlex="100"
            mat-raised-button
            type="button">
      Fechar
    </button>
  </div>
</div>
