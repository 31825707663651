import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'person-exam-request-dialog',
  templateUrl: './person-exam-request-dialog.component.html',
})
export class PersonExamRequestDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<PersonExamRequestDialogComponent>,
  ) {}

  ngOnInit(): void {}

  close() {
    this.dialogRef.close(true);
  }
}
