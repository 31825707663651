import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { noop, Observable, Unsubscribable } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  AutoUnsubscribe,
  CombineSubscriptions,
} from '../../../../shared/decorators/auto-unsubscribe.decorator';
import { AppConfirmService } from '../../../../shared/services/app-confirm/app-confirm.service';
import { UrlService } from '../../../../shared/services/url.service';
import { Specialty } from '../../../../state/models/specialty';
import { SpecialtyEntityService } from '../../../../state/entity-services/specialty-entity.service';

@Component({
  selector: 'person-specialties-autocomplete',
  templateUrl: './person-specialties-autocomplete.component.html',
  styleUrls: ['./person-specialties-autocomplete.component.scss'],
})
@AutoUnsubscribe()
export class PersonSpecialtiesAutocompleteComponent
  implements OnInit, OnDestroy
{
  @CombineSubscriptions()
  subscriptions: Unsubscribable;
  @Input() clearForm: boolean;
  @Output() callback: EventEmitter<Specialty> = new EventEmitter<Specialty>();

  searchControl = new FormControl(null, [Validators.required]);

  options: Specialty[] = [];
  filteredOptions: Observable<Specialty[]>;
  hasFind: boolean;

  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

  constructor(
    private router: Router,
    private confirmService: AppConfirmService,
    private route: ActivatedRoute,
    private urlService: UrlService,
    private specialtyEntityService: SpecialtyEntityService,
  ) {
    this.subscriptions = route.data
      .pipe(
        tap(data => {
          this.filteredOptions = this.searchControl.valueChanges.pipe(
            tap(() => this.sendData(null)),
            debounceTime(300),
            startWith(value => value.length > 0),
            map(response => {
              if (typeof response !== 'string') {
                return response.specialties;
              }
              return response ? response?.trim() : '';
            }),
            filter(value => {
              return typeof value === 'string';
            }),
            switchMap(q => {
              return this.specialtyEntityService.getWithQuery({ q });
            }),
            map((value: Specialty[]) => {
              this.hasFind = true;
              this.options = value;
              return value;
            }),
          );
        }),
      )
      .subscribe(noop);
  }

  ngOnInit(): void {
    if (this.clearForm) {
      this.searchControl.clearValidators();
    }
  }

  ngOnDestroy(): void {}

  onSelection($event: MatOptionSelectionChange, preSelect?) {
    if ($event.isUserInput) {
      const { specialty } = $event?.source?.value || preSelect;
      this.sendData(specialty);
    }
  }

  sendData(specialty) {
    if (specialty !== null) {
      if (this.clearForm) {
        this.searchControl.reset();
      }
      if (specialty !== null) {
        this.callback.emit(specialty);
      }
    }
  }

  displayFn(response): string {
    const { specialty } = response || {};
    if (!specialty) {
      return '';
    }
    const { name } = specialty || {};
    return `${name}`;
  }
}
