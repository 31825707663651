import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Person } from '../../../../state/models/person';
import { UtilsService } from '../../../../shared/services/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { noop, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ExamParses, Exams, PerformedExam } from '../../../../state/models/exams';
import { PersonPerformedExamFormService } from '../../services/person-form/person-performed-exam-form.service';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { PerformedExamsEntityService } from '../../../../state/entity-services/performed-exams-entity.service';
import { DateService } from '../../../../shared/services/date.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ActivatedRoute } from '@angular/router';
import { Timeout } from '../../../../shared/decorators/timeout-method.decorator';
import { Laboratory } from '../../../../state/models/laboratory';
import { ExamParsesEntityService } from '../../../../state/entity-services/exam-parses-entity.service';
import { PdfService } from '../../../../shared/services/pdf.service';

@Component({
  selector: 'person-perform-exam-form-dialog',
  styleUrls: ['./person-perform-exam-form-dialog.component.scss'],
  templateUrl: './person-perform-exam-form-dialog.component.html',
})
export class PersonPerformExamFormDialogComponent
  implements OnInit, AfterViewInit, AfterContentInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  formConfigDefault: any;
  formConfigs: any[] = [];
  values: any = {};
  examPrev: Exams;
  performedExamPrev: PerformedExam;
  performedExam: PerformedExam;
  performedExams: PerformedExam[];
  showRemoveButton: boolean;
  public person: Person = this.personEntityService.lastPersonWithAge;
  loading: boolean;
  isValid: boolean;
  tabIndex: number;
  public typeFile: string;
  public examParseActive = false;
  private fileUrlRequired: boolean;


  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    $event.returnValue = true; // Standard for most browsers
  }

  constructor(
    private pdfService: PdfService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dateService: DateService,
    private personPerformedExamFormService: PersonPerformedExamFormService,
    private examParsesEntityService: ExamParsesEntityService,
    private utilsService: UtilsService,
    private dialogRef: MatDialogRef<PersonPerformExamFormDialogComponent>,
    private performedExamsEntityService: PerformedExamsEntityService,
    private personEntityService: PersonEntityService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const { person, performedExam } = this.data;
    this.performedExamPrev = performedExam;
    this.person = person;
    this.values = { person: { id: person.id } };
  }

  ngOnInit(): void {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};
    this.formConfigDefault = {
      form,
      options,
      fields: [
        {
          className: 'section-label',
          template: '<h2>Dados dos exames</h2>',
        },
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'column',
          },
          fieldGroup: [
            {
              type: 'masked',
              key: 'performedAt',
              defaultValue: '',
              props: {
                label: 'Feito em',
                placeholder: '00/00/0000',
                mask: 'DATE',
                required: true,
              },
            },
          ],
        },
      ],
    };
    setTimeout(() => {
      this.update();
    }, 100);
  }

  ngAfterContentInit() {
    this.cdr.detectChanges();
  }

  @Timeout(100)
  ngAfterViewInit() {
    const ids = [];
    if (
      !!this.performedExamPrev &&
      Array.isArray(this.performedExamPrev) &&
      this.performedExamPrev.length > 0
    ) {
      this.examParseActive = true;
      this.performedExam = this.performedExamPrev[0];
      this.performedExamPrev.forEach(pe => {
        const { exam, examResults } = pe;
        if (exam?.id && !ids.includes(exam?.id)) {
          ids.push(exam.id);
        }
        examResults?.forEach(er => {
          this.values[er.expectedExamResult.name] = er.value?.toString();
        });
      });

      if (ids.length > 0) {
        this.getExams(ids.join(','));
      }

      this.setPopulate(this.performedExam);
    } else if (this.performedExamPrev) {
      this.setPopulate(this.performedExamPrev);
      if (this.values?.exam?.id) {
        this.getExams(this.values.exam.id);
      }
      this.performedExam = this.performedExamPrev;
    }
    const { examParse } = this.values;
    if (examParse) {
      const { fileMimeType } = examParse as ExamParses;
      if (fileMimeType.indexOf('pdf') > -1) {
        this.typeFile = 'pdf';
      } else if (fileMimeType.indexOf('image') > -1) {
        this.typeFile = 'image';
      }
    }
    this.setPdf();
  }

  setPdf(): void {
    if (this.values?.documentUrl) {
      this.pdfService.pdfViewer(this.values.documentUrl, 'pdf-content');
    }
  }

  getExams(ids): void {
    if (!ids) {
      return;
    }
    this.performedExamsEntityService.getExams({ ids }).subscribe(res => {
      res.forEach(exam => {
        this.setInput(exam);
      });
    });
  }

  setPopulate(performedExam: PerformedExam): void {
    if (performedExam) {
      const { examResults, performedAt, documentUrl } = performedExam;
      this.showRemoveButton = !!documentUrl;
      examResults?.map(er => {
        this.values[er.expectedExamResult.name] = er.value?.toString();
      });
      this.values = {
        ...this.values,
        ...performedExam,
        performedExamId: performedExam?.id,
        performedAt: performedAt
          ? this.dateService.format(performedAt, 'dateLocal')
          : undefined,
      };
    }
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (this.progressBar) {
      this.progressBar.mode = loading ? 'indeterminate' : 'determinate';
    }
  }

  submit() {
    if (this.checkIsValid()) {
      const body = {
        defaultValues: this.values,
        formConfigs: this.formConfigs,
        formConfigDefault: this.formConfigDefault,
      };
      this.setLoading(true);
      this.performedExamsEntityService
        .save(body)
        .pipe(
          tap(() => {
            this.setLoading(false);
            this.dialogRef.close(true);
          }),
          catchError(error => {
            this.setLoading(false);
            const { message } = error || {};
            this.utilsService.toast(
              message || 'Erro desconhecido da api',
              'Ok',
            );
            throw error;
          }),
        )
        .subscribe(noop);
    }
  }

  public getFileData(file): void {
    this.values.documentUrl = file.signedGetUrl;
    this.setPdf();
    this.update();
  }

  public getAutocomplete(exam: Exams): void {
    if (exam && typeof exam !== 'string') {
      this.examPrev = exam;
      this.setInput(exam);
      const { fileUrlRequired } = exam;
      if (fileUrlRequired) {
        this.fileUrlRequired = fileUrlRequired;
      }
      this.update();
    }
  }

  setInput(exam: Exams): void {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};

    if (this.formConfigs.find(f => f.exam.id === exam.id)) {
      return;
    }
    const formConfig = {
      exam,
      form,
      options,
      fields: [
        {
          className: 'section-label',
          template: '<label>Resultados</label>',
        },
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'column',
          },
          fieldGroup: this.personPerformedExamFormService.getInputs(
            exam.expectedExamResults,
          ),
        },
        {
          className: 'section-label',
          template: '<label>Laudo</label>',
        },
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'column',
          },
          fieldGroup: [
            {
              type: 'textarea',
              key: `report`,
              defaultValue: '',
              props: {
                rows: 3,
                placeholder: 'Laudo',
              },
            },
          ],
        },
      ],
    };
    this.formConfigs.push(formConfig);
    this.tabIndex = this.formConfigs?.length - 1;
  }

  formRemoveByExamId(idExam: number) {
    if (this.performedExam && this.performedExam.id) {
      return;
    }
    this.formConfigs = this.formConfigs.filter(exam => exam.exam.id !== idExam);
    this.fileUrlRequired = this.formConfigs.some(formConfig => {
      return formConfig.exam.fileUrlRequired;
    });
    this.update();
  }

  checkAllFormIsValid() {
    if (!this.formConfigs.length) {
      return false;
    }
    return !!this.formConfigs.every(form => {
      return form.form.status !== 'INVALID';
    });
  }

  public checkIsValid(): boolean {
    if (!this.values) {
      return false;
    }
    const { person } = this.values;
    return (
      this.checkDocumentUrlRequired() &&
      person &&
      this.checkAllFormIsValid() &&
      this.formConfigDefault.form.status !== 'INVALID'
    );
  }

  @Timeout(300)
  update() {
    this.isValid = this.checkIsValid();
  }

  getAutocompleteLaboratory(laboratory: Laboratory) {
    if (laboratory) {
      this.values.laboratory = { ...laboratory };
    } else {
      this.values.laboratory = null;
    }
    this.update();
  }

  removeLaboratory() {
    this.values.laboratory = null;
    this.update();
  }

  identifyMediaType(url: string): string | null {
    return this.utilsService.identifyMediaType(url);
  }

  urlEncode(documentUrl: string) {
    return encodeURIComponent(documentUrl);
  }

  private checkDocumentUrlRequired() {
    if (this.fileUrlRequired) {
      return !!this.values.documentUrl;
    }
    return true;
  }
}
