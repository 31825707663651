import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { DateService } from '../../services/date.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  env = environment;
  year: string;

  constructor(private dateService: DateService) {
    this.year = this.dateService.getLocalDateTime('YYYY');
  }

  ngOnInit() {}
}
