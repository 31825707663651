import { OnDestroy, Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { DateService } from '../services/date.service';
import { TranslationService } from '../services/translation.service';
import { Translation } from '../../views/persons/services/person-risks.service';
import {
  AutoUnsubscribe,
  CombineSubscriptions,
} from '../decorators/auto-unsubscribe.decorator';
import { Unsubscribable } from 'rxjs';

@Pipe({
  name: 'translation',
})
export class TranslationPipe implements PipeTransform {
  constructor(private translateService: TranslationService) {}

  transform(index: string, field: string, translations: Translation[]): string {
    return this.translateService.translate(index, field, translations);
  }
}
