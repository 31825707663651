import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { SharedImageCropDialogComponent } from '../dialogs/shared-image-crop-dialog/shared-image-crop-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class ImageCropService {

  constructor(private dialog: MatDialog) {
  }

  public openImageCropDialog(
    image: any,
    config: ImageCropConfig,
  ): Observable<any> {
    let dialogRef: MatDialogRef<SharedImageCropDialogComponent>;
    dialogRef = this.dialog.open(SharedImageCropDialogComponent, {
      width: '512px',
      data: { image, config },
    });
    return dialogRef.afterClosed();
  }

}

export interface ImageCropConfig {
  size: number;
  cropperMinWidth: number;
  aspectRatio: any;
  title?: string;
}
