import { AbstractControl, FormGroup, Validators } from '@angular/forms';
export class GenericValidator {

  constructor() { }

  static formlyMinLengthValidationMessage(_err: any, field: { templateOptions: { minLength: any; }; }) {
    return `Preencha no mínimo ${field.templateOptions.minLength} caracteres`;
  }

  static fieldMatchValidator(control: AbstractControl) {
    const { password, password_confirmation } = control.value;

    // avoid displaying the message error when values are empty
    if (!password_confirmation || !password) {
      return null;
    }

    if (password_confirmation === password) {
      return null;
    }

    return { fieldMatch: { message: 'Senhas não coincidem' } };
  }


  /**
   * Check if CPF is Valid
   */
  static isValidCpf() {
    return (control: AbstractControl): Validators => {
      const cpf = control.value;
      if (cpf) {
        let numbers, digits, sum, i, result, equalDigits;
        equalDigits = 1;
        if (cpf.length < 11) {
          return null;
        }

        for (i = 0; i < cpf.length - 1; i++) {
          if (cpf.charAt(i) !== cpf.charAt(i + 1)) {
            equalDigits = 0;
            break;
          }
        }

        if (!equalDigits) {
          numbers = cpf.substring(0, 9);
          digits = cpf.substring(9);
          sum = 0;
          for (i = 10; i > 1; i--) {
            sum += numbers.charAt(10 - i) * i;
          }

          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(0))) {
            return { cpfNotValid: true };
          }
          numbers = cpf.substring(0, 10);
          sum = 0;

          for (i = 11; i > 1; i--) {
            sum += numbers.charAt(11 - i) * i;
          }
          result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

          if (result !== Number(digits.charAt(1))) {
            return { cpfNotValid: true };
          }
          return null;
        } else {
          return { cpfNotValid: true };
        }
      }
      return null;
    };
  }

  static isValidCnpj() {
    return (control: AbstractControl): Validators => {
      const cnpj = control.value;
      if (cnpj) {
        if (cnpj === '') {
          return { cnpjNotValid: true };
        }
        if (cnpj.length !== 14) {
          return { cnpjNotValid: true };
        }
        if (cnpj === '00000000000000' ||
          cnpj === '11111111111111' ||
          cnpj === '22222222222222' ||
          cnpj === '33333333333333' ||
          cnpj === '44444444444444' ||
          cnpj === '55555555555555' ||
          cnpj === '66666666666666' ||
          cnpj === '77777777777777' ||
          cnpj === '88888888888888' ||
          cnpj === '99999999999999') {
          return { cnpjNotValid: true };
        }
        let size = cnpj.length - 2;
        let numbers = cnpj.substring(0, size);
        const digits = cnpj.substring(size);
        let sum = 0;
        let position = size - 7;
        for (let i = size; i >= 1; i--) {
          sum += numbers.charAt(size - i) * position--;
          if (position < 2) {
            position = 9;
          }
        }
        let result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== parseInt(digits.charAt(0), 10)) {
          return { cnpjNotValid: true };
        }

        size = size + 1;
        numbers = cnpj.substring(0, size);
        sum = 0;
        position = size - 7;
        for (let i = size; i >= 1; i--) {
          sum += numbers.charAt(size - i) * position--;
          if (position < 2) {
            position = 9;
          }
        }
        result = sum % 11 < 2 ? 0 : 11 - sum % 11;
        if (result !== parseInt(digits.charAt(1), 10)) {
          return { cnpjNotValid: true };
        }
      }
      return null;
    };
  }

  static isFieldEquals(firstField: string, secondField) {
    return (formGroup: FormGroup): Validators => {
      if (formGroup) {
        const firstValue = formGroup.controls[firstField].value;
        const secondValue = formGroup.controls[secondField].value;
        if (!firstValue || !secondValue) {
          return null;
        }
        if (firstValue !== secondValue) {
          return { unequals: true };
        }
      }
      return null;
    };
  }
}
