import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { Person } from '../../../../state/models/person';
import { DateService } from '../../../../shared/services/date.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { Appointment } from '../../../../state/models/appointment';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { noop, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PatientNotesEntityService } from '../../../../state/entity-services/patient-notes-entity.service';
import { AppointmentsEntityService } from '../../../../state/entity-services/appointments-entity.service';

@Component({
  selector: 'person-appointments-form-dialog',
  templateUrl: './person-appointments-form-dialog.component.html',
})
export class PersonAppointmentsFormDialogComponent
  implements OnInit, AfterViewInit
{
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  formConfig: any;
  values: any = {};
  entity: Appointment;
  loading: boolean;
  public person: Person = this.personEntityService.lastPersonWithAge;

  constructor(
    private appointmentsEntityService: AppointmentsEntityService,
    private cdr: ChangeDetectorRef,
    private dateService: DateService,
    private utilsService: UtilsService,
    private dialogRef: MatDialogRef<PersonAppointmentsFormDialogComponent>,
    private personEntityService: PersonEntityService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const { appointment, person } = data;
    this.entity = appointment;
    this.person = person;
  }

  ngOnInit(): void {
    this.values = {
      person: { id: this.person.id },
    };
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};
    this.formConfig = {
      form,
      options,
      fields: [
        {
          type: 'datepicker',
          key: 'startedAt',
          defaultValue: '',
          props: {
            label: 'Iniciado em:',
            placeholder: '00/00/0000',
            required: true,
            readonly: true,
          },
        },
        {
          type: 'masked',
          key: 'startedAtTime',
          defaultValue: '',
          props: {
            label: 'Horario iniciado em:',
            mask: 'TIME',
            placeholder: '00:00:00',
          },
        },
        {
          type: 'datepicker',
          key: 'endedAt',
          defaultValue: '',
          props: {
            label: 'Terminado em:',
            placeholder: '00/00/0000',
            required: true,
            readonly: true,
          },
        },
        {
          type: 'masked',
          key: 'endedAtTime',
          defaultValue: '',
          props: {
            label: 'Horario terminado em:',
            mask: 'TIME',
            placeholder: '00:00:00',
          },
        },
      ],
    };
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.populate();
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (this.progressBar) {
      this.progressBar.mode = loading ? 'indeterminate' : 'determinate';
    }
  }

  populate(): void {
    if (this.entity) {
      const { startedAt, endedAt } = this.entity;
      const startedAtTime = this.dateService.formatPipe(startedAt, 'HH:mm:ss');
      const endedAtTime = this.dateService.formatPipe(endedAt, 'HH:mm:ss');
      this.values = {
        ...this.values,
        ...this.entity,
        startedAtTime,
        endedAtTime,
      };
    }
  }

  public checkIsValid(): boolean {
    if (!this.values) {
      return false;
    }
    const { person } = this.values;
    return !!person && this.formConfig?.form?.status === 'VALID';
  }

  submit() {
    if (this.checkIsValid()) {
      this.setLoading(true);
      this.appointmentsEntityService
        .updateAppointment(this.values)
        .pipe(
          tap(() => {
            this.setLoading(false);
            this.dialogRef.close(true);
          }),
          catchError(err => {
            this.setLoading(false);
            const { message } = err || {};
            this.utilsService.toast(
              message || 'Erro desconhecido da api',
              'Ok',
            );
            throw err;
          }),
        )
        .subscribe(noop);
    }
  }

  update(update) {
    this.values = update;
  }
}
