import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { noop, Observable, Unsubscribable } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  AutoUnsubscribe,
  CombineSubscriptions,
} from '../../../../shared/decorators/auto-unsubscribe.decorator';
import { AppConfirmService } from '../../../../shared/services/app-confirm/app-confirm.service';
import { UrlService } from '../../../../shared/services/url.service';
import { Exams } from '../../../../state/models/exams';
import { PerformedExamsEntityService } from '../../../../state/entity-services/performed-exams-entity.service';

@Component({
  selector: 'person-exams-autocomplete',
  templateUrl: './person-exams-autocomplete.component.html',
  styleUrls: ['./person-exams-autocomplete.component.scss'],
})
@AutoUnsubscribe()
export class PersonExamsAutocompleteComponent implements OnInit, OnDestroy {
  @CombineSubscriptions()
  subscriptions: Unsubscribable;

  @Input() id: number | undefined;
  @Input() clearForm: boolean;
  @Output() callback: EventEmitter<Exams> = new EventEmitter<Exams>();

  searchControl = new FormControl(null);

  options: Exams[] = [];
  filteredOptions: Observable<Exams[]>;
  hasFind: boolean;
  disableAutocomplete: boolean;

  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

  constructor(
    private router: Router,
    private confirmService: AppConfirmService,
    private route: ActivatedRoute,
    private urlService: UrlService,
    private performedExamsEntityService: PerformedExamsEntityService,
  ) {}

  ngOnInit(): void {
    this.subscriptions = this.route.data
      .pipe(
        tap(data => {
          this.filteredOptions = this.searchControl.valueChanges.pipe(
            tap(() => this.sendData(null)),
            debounceTime(300),
            startWith(value => value.length > 0),
            map(response => {
              if (typeof response !== 'string') {
                return response.exams;
              }
              return response ? response?.trim() : '';
            }),
            filter(value => {
              return typeof value === 'string';
            }),
            switchMap(q => {
              return this.performedExamsEntityService.getExams({ q });
            }),
            map((value: Exams[]) => {
              this.hasFind = true;
              this.options = value;
              setTimeout(() => {
                if (this.id) {
                  this.searchControl.disable();
                }
              }, 10);
              return value;
            }),
          );
        }),
      )
      .subscribe(noop, () => {});
  }

  ngOnDestroy(): void {}

  onSelection($event: MatOptionSelectionChange, preSelect?) {
    if ($event?.isUserInput) {
      const { exam } = $event?.source?.value || preSelect;
      this.sendData(exam);
      this.searchControl.setValue('', { emitEvent: false });
    }
  }

  sendData(exam) {
    if (exam !== null) {
      if (this.clearForm) {
        this.searchControl.reset();
      }

      this.callback.emit(exam);
    }
  }

  displayFn(response): string {
    const { exam } = response || {};
    if (!exam) {
      return '';
    }
    const { name } = exam || {};
    return `${name}`;
  }
}
