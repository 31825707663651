import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { AutoUnsubscribe, CombineSubscriptions } from '../../../../shared/decorators/auto-unsubscribe.decorator';
import { noop, of, switchMap, tap, Unsubscribable } from 'rxjs';
import { Person } from '../../../../state/models/person';
import { MatProgressBar } from '@angular/material/progress-bar';
import { Timeout } from '../../../../shared/decorators/timeout-method.decorator';
import { PersonDialogsService } from '../../services/person-dialogs.service';
import { NavigationEnd, Router } from '@angular/router';
import { UrlService } from '../../../../shared/services/url.service';
import { filter } from 'rxjs/operators';
import { RouterParamsService } from '../../../../shared/services/router-params.service';
import { CrmPersons } from '../../../../state/models/crm-persons';
import { UtilsService } from '../../../../shared/services/utils.service';

@Component({
  selector: 'person-header',
  templateUrl: './person-header.component.html',
})
@AutoUnsubscribe()
export class PersonHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  public person: Person = this.personEntityService.lastPersonWithAge;
  showHeader = true;
  showMore = false;
  @CombineSubscriptions()
  private subscriptions: Unsubscribable;
  phoneStatus: string;
  crmPersons: CrmPersons;
  crmPersonsOldId: number;

  constructor(
    private utilsService: UtilsService,
    private urlService: UrlService,
    private router: Router,
    public personDialogsService: PersonDialogsService,
    private personEntityService: PersonEntityService,
    private routerParamsService: RouterParamsService,
  ) {
  }

  ngOnInit(): void {
    this.subscriptions = this.router.events
      .pipe(
        filter((event: any) => {
          return event instanceof NavigationEnd;
        }),
        switchMap(() => {
          return this.routerParamsService.params;
        }),
      )
      .subscribe(params => {
        this.showHeader = !!params.personId;
        if (params.personId) {
          this.getCRMPersons(parseInt(params.personId, 10));
        }

        if (!this.showHeader) {
          this.person = undefined;
        }
      });
  }

  @Timeout(100)
  ngAfterViewInit() {
    this.subscriptions = this.personEntityService
      .getCurrentWithAge()
      .pipe(
        tap(person => {
          this.person = person;
          const { phoneStatus, id } = person;
          if (id) {
            this.getCRMPersons(id);
          }
          this.phoneStatus = this.getPhoneStatus(phoneStatus);
        }),
      )
      .subscribe(noop);
  }

  ngOnDestroy() {
    this.crmPersonsOldId = undefined;
  }

  openDialog() {
    this.personDialogsService
      .openPersonEdit(this.person)
      .pipe(
        switchMap((response: boolean) => {
          if (!response) {
            return of('');
          }
          return this.personEntityService.getCurrentWithAge().pipe(
            tap(person => {
              this.person = person;
              const { phoneStatus } = person;
              this.phoneStatus = this.getPhoneStatus(phoneStatus);
            }),
          );
        }),
      )
      .subscribe(noop);
  }

  getPhoneStatus(phoneStatus: string) {
    if (phoneStatus === 'VERIFIED') {
      return 'Telefone verificado!';
    }
    if (phoneStatus === 'UNVERIFIED') {
      return 'Telefone não verificado!';
    }
  }

  getLink() {
    this.router.navigateByUrl(
      this.urlService.personDetailsSelfieLink(this.person.id),
    );
  }

  private getCRMPersons(personId: number): void {
    const { id } = this.person || {};
    if (id === this.crmPersonsOldId) {
      return;
    }

    this.crmPersonsOldId = id;
    this.personEntityService.crmPersons(personId)
      .pipe(
        tap(crmPerson => {
          this.crmPersons = crmPerson;
        }),
      )
      .subscribe({
        error: err => {
          this.utilsService.setErrorToast(err);
        },
      });
  }
}
