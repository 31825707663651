import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {
  }

  transform(value: string, status?: boolean, icon?: boolean): string {

    if (status) {
      if (value === 'VERIFIED') {
        return 'verificado';
      }
      if (value === 'UNVERIFIED') {
        return 'não verificado';
      }
      return '';
    }

    if (icon) {
      if (value === 'VERIFIED') {
        return 'verified';
      }
      if (value === 'UNVERIFIED') {
        return 'pending';
      }
      return '';
    }


    return this.utilsService.phoneFormat(value);
  }
}
