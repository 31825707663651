import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { JwtAuthService } from '../services/auth/jwt-auth.service';
import { Observable } from 'rxjs';
import { PersonEntityService } from '../../state/entity-services/person-entity.service';
import { catchError, map } from 'rxjs/operators';
import { UtilsService } from '../services/utils.service';

@Injectable()
export class CopyLinkPersonSelfieGuard  {
  constructor(
    private utilsService: UtilsService,
    private personEntityService: PersonEntityService,
    private router: Router,
    private jwtAuth: JwtAuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> {
    const personId = this.findPersonId(route);
    if (!personId) {
      this.utilsService.toast('Erro ao copiar selfie', 'Error');
    }
    return this.personEntityService
      .generateSelfieLink(route.params.personId)
      .pipe(
        map(selfieUrl => {
          this.utilsService.copyClipboard(selfieUrl, 'Link da selfie');
          return false;
        }),
        catchError(error => {
          const { message } = error || {};
          this.utilsService.toast(message || 'Erro desconhecido da api', 'Ok');
          throw error;
        }),
      );
  }

  private findPersonId(route) {
    const { personId } = route.params || {};
    if (personId) {
      return personId;
    }
    if (!route?.parent) {
      return undefined;
    }
    return this.findPersonId(route.parent);
  }
}
