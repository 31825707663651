<div class="sidebar-panel">
  <div class="navigation-hold" fxLayout="column" id="scroll-area">
    <div class="sidebar-hold">
      <!-- App Logo -->
      <a class="branding" routerLink="/" style="padding: 0">
        <img alt="Logo mar saude" src="assets/images/logos/logo-mar.png"
             style="display: block; margin: 10px auto 0"
             width="100">
      </a>
      <!-- Navigation -->
      <app-sidenav [hasIconMenu]="hasIconTypeMenuItem" [iconMenuTitle]="iconTypeMenuTitle"
      ></app-sidenav>
    </div>
  </div>
</div>
