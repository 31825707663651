import { Injectable } from '@angular/core';
import { Token } from '../models/token';
import { Observable } from 'rxjs';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RouterParamsService } from '../../shared/services/router-params.service';

@Injectable({
  providedIn: 'root',
})
export class TokenEntityService extends BaseEntityService<Token> {
  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'token',
        plural: 'tokens',
        url: 'token'
      },
      routerParamsService
    );
    this.nameId = 'tokenId';
  }

  getToken(code: string, phone: string, document: string): Observable<Token> {
    const body: Token = {
      grantType: 'RECIPIENT_VALIDATION_CODE',
      code,
      document,
      phone
    };
    return this.add(body, true);
  }

    getTokenGeneral(body: any): Observable<Token> {
    return this.add(body, true);
  }
}
