import { getDocument, GlobalWorkerOptions, version } from 'pdfjs-dist';
import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  private document: Document;
  private thePdf: any;
  private scale = 1.5;

  constructor(@Inject(DOCUMENT) document) {
    this.document = document;
    GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
  }

  public pdfViewer(pdfFile, pdfIdContent): void {
    // Asynchronous download of PDF
    getDocument(pdfFile).promise.then(pdf => {
      this.thePdf = pdf;
      // Prepare canvas using PDF page dimensions
      const viewer = document.getElementById(pdfIdContent);
      viewer.innerHTML = '';
      for (let page = 1; page <= pdf.numPages; page++) {
        // @ts-ignore
        const canvas = document.createElement('canvas');
        canvas.className = 'pdf-page-canvas';
        viewer.appendChild(canvas);
        this.renderPage(page, canvas);
      }
    });
  }

  renderPage(pageNumber, canvas) {
    this.thePdf.getPage(pageNumber).then(page => {
      // @ts-ignore
      const viewport = page.getViewport({ scale: this.scale });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      page.render({
        canvasContext: canvas.getContext('2d'),
        viewport,
      });
    });
  }
}
