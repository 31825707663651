<form class="autocomplete">
  <mat-form-field appearance="outline" class="width-100" style="height: 56px">
    <mat-label>Digite para cadastrar um novo laboratório</mat-label>
    <input [formControl]="searchControl"
           [matAutocomplete]="auto"
           aria-label="Laboratorio"
           matInput
           placeholder="Digite um laboratório:"
           type="text">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="getPosts($event)" [displayWith]="displayFn"
                      autoActiveFirstOption>
      <mat-option (onSelectionChange)="onSelection($event)"
                  *ngFor="let option of filteredOptions | async"
                  [value]="{laboratory: option}">
        {{option?.name}}
      </mat-option>
      <mat-option *ngIf="hasFind">
        <a (click)="createNewLaboratory()" style="display: block">Clique para criar esse novo laboratório</a>
      </mat-option>
    </mat-autocomplete>

    <mat-error *ngIf="!searchControl.hasError('required')">
      Campo obrigatório
    </mat-error>
  </mat-form-field>
</form>
