import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { EntityState } from '../../../models/entity-state.model';

@Injectable({
  providedIn: 'root'
})
export class EntityManagementService {
  private globalState: { [key: string]: WritableSignal<EntityState<any>> } = {};
  private servicesEntity = [];

  getState<T>(entityKey: string): Signal<EntityState<T>> {
    return this.globalState[entityKey].asReadonly();
  }

  registerEntity<T>(entityKey: string, initialState: EntityState<T>): void {
    this.globalState[entityKey] = signal(initialState);
  }

  registrerServices(services: any[]) {
    this.servicesEntity.push(...services);
  }

  public clearAllCache(): void {
    this.servicesEntity.forEach((entityService: any) => {
      entityService.clearAll();
    });
  }

  public clearCacheByEntity(singularEntityName: string): void {
    const entityService = this.servicesEntity.find(entityService => entityService.singularEntityName === singularEntityName);
    if (entityService) {
      entityService.clearAll();
    }
  }
}
