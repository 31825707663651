<div *ngIf="showHeader && person">
  <mat-progress-bar class="custom-progress" mode="determinate"></mat-progress-bar>
  <h2 style="text-align: center;margin-bottom: 0; margin-top: 8px">
<span fxLayout="row" fxLayoutAlign="center center">
  <span>{{ person?.personProperties?.name }}</span>
    <button  *permissionControl="['person#update']" (click)="openDialog()" mat-icon-button>
        <mat-icon>edit</mat-icon>
    </button>
</span>
  </h2>
  <div style="padding: 8px 16px;">
    <div fxLayout="column" fxLayoutGap="16px" style="padding: 4px 8px;">
      <div class="w-100" fxLayout="column" fxLayoutGap="16px">
        <div fxFlex="100">
          <ul class="list">
            <li>
              <label>Sexo biológico:</label>
              <span>{{ person?.personProperties?.biologicalSex | stringFormat }}</span>
            </li>
            <li>
              <label>Idade:</label>
              <span>{{ person?.age }} anos</span>
            </li>
            <li>
              <label>Organizações:</label>
              <span [innerHTML]="person.organizationNames"></span>
            </li>
            <li *ngIf="crmPersons?.digitalStatus || crmPersons?.digitalStatusForced">
              <label>
                Digital Status:
              </label>
              <span>
                {{ crmPersons?.digitalStatus ?? crmPersons?.digitalStatusForced  }} <mat-icon
                matTooltip="Status digital forçado"
                style="font-size: 15px; width: 15px; height: 15px; color: darkred"
                *ngIf="crmPersons?.digitalStatusForced">report</mat-icon>
              </span>
            </li>
          </ul>
        </div>

        <a (click)="showMore = !showMore" style="padding: 0;margin: 16px auto;height: 20px; text-align: center">Mais
          detalhes</a>

        <div *ngIf="showMore" fxFlex="100" fxLayout="column" fxLayoutGap="16px">
          <ul class="list">
            <li>
              <label>ID:</label>
              <span>{{ person.id }}</span>
            </li>
            <li>
              <label>CPF:</label>
              <span>{{ person.document | document }}</span>
            </li>
            <li class="row">
              <label>Cel:</label>
              <span class="icon">
                {{ person.phone | phone }}
                <mat-icon
                  [matTooltip]="phoneStatus" matTooltipPosition="above"
                  [class.verified]="person.phoneStatus ==='VERIFIED'"
                  [class.unverified]="person.phoneStatus ==='UNVERIFIED'"
                >{{ person.phoneStatus | phone: false: true }}</mat-icon>
              </span>
            </li>
            <li class="row">
              <label>E-mail: <br /></label>
              <span>{{ person.email }}</span>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</div>
