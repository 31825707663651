import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { JwtAuthService } from '../../services/auth/jwt-auth.service';
import { NavigationService } from '../../services/navigation.service';
import { environment } from '../../../../environments/environment';
import {
  AppConfirmService,
  DataConfirm,
} from '../../services/app-confirm/app-confirm.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html',
})
export class SidenavComponent {
  version = environment.version;
  public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;

  constructor(
    private confirmService: AppConfirmService,
    private navService: NavigationService,
    public jwtAuth: JwtAuthService,
  ) {
    this.navService.menuItems$.subscribe(menuItem => {
      this.menuItems = menuItem;
    });
  }

  logout() {
    const data: DataConfirm = {
      buttonConfirm: {
        label: 'Sair',
        show: true,
      },
      title: 'Deseja realmente sair?',
      message: '',
    };
    this.confirmService.confirm(data).subscribe(response => {
      if (response) {
        this.jwtAuth.signout();
      }
    });
  }
}
