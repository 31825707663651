<form (ngSubmit)="submit()" [formGroup]="formConfig?.form">
  <h1 mat-dialog-title>Dados da consulta</h1>
  <div mat-dialog-content>
    <mat-progress-bar class="custom-progress" mode="determinate"></mat-progress-bar>
    <formly-form
      (modelChange)="update($event)"
      *ngIf="!!formConfig?.fields"
      [fields]="formConfig.fields"
      [form]="formConfig.form"
      [model]="values"
      [options]="formConfig.options"
    ></formly-form>
  </div>
  <div mat-dialog-actions>
    <div fxFill fxLayout="row">
      <button color="accent"
              fxFlex="50"
              mat-dialog-close
              mat-raised-button
              type="button">
        Cancelar
      </button>
      <button [disabled]="!checkIsValid() || loading"
              color="primary"
              fxFlex="50"
              mat-raised-button
              type="submit">
        Salvar
      </button>
    </div>
  </div>
</form>
