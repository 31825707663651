import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExamRequest, Exams } from '../models/exams';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { BasePermissionsService } from '../entity-management/services/base/base-permissions.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ExamRequestsEntityService extends BaseEntityService<ExamRequest> {

  constructor(
    basePermissionsService: BasePermissionsService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'examRequest', plural: 'examRequests', url: 'exam-requests' },
      routerParamsService,
      basePermissionsService.examRequest,
    );
    this.nameId = 'examRequestId';
  }


  save(dataToSave: any, exams: Exams[]): Observable<ExamRequest> {
    const nDataToSave: any = { ...dataToSave };
    Object.keys(nDataToSave).forEach(key => {
      if (!nDataToSave[key]) {
        delete nDataToSave[key];
      }
    });
    const { examId } = nDataToSave;
    const body = {
      ...nDataToSave,
      exams: exams.map(e => {
        return { id: e.id };
      }),
      id: examId || null,
    };
    if (body.id) {
      return this.update(body);
    }
    return this.add(body);
  }

  autoGenerate(selfieConsolidatedId: number): Observable<any> {
    this.validatePermission('autoGenerate', true);
    const body = { selfieConsolidated: { id: selfieConsolidatedId } };
    return this.httpClient
      .post(`${ this.entityUrl }/auto-generate`, body)
      .pipe(map((response: any) => response?.autoGenerate));
  }

  regenerateDocument(examRequestId: number): Observable<any> {
    this.validatePermission('regenerateDocument', true);
    return this.httpClient
      .post(`${ this.entityUrl }/${ examRequestId }/regenerate-document`, {})
      .pipe(map((response: any) => response?.autoGenerate));
  }
}
