import { Pipe, PipeTransform } from '@angular/core';
import { FormAnswer } from '../../state/models/form.model';
import { PersonFormService } from '../../views/persons/services/person-form.service';

@Pipe({
  name: 'formAnswer',
})
export class FormAnswersPipe implements PipeTransform {
  constructor(private personFormService: PersonFormService) {}

  transform(formAnswer: FormAnswer): string | number {
    return this.personFormService.getFormAnswersFormat(formAnswer);
  }
}
