import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { Unsubscribable } from 'rxjs';
import {
  AutoUnsubscribe,
  CombineSubscriptions,
} from '../../decorators/auto-unsubscribe.decorator';
import { LayoutService } from '../../services/layout.service';
import { RouterParamsService } from '../../services/router-params.service';
import { ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html',
})
@AutoUnsubscribe()
export class HeaderTopComponent implements OnInit, OnDestroy {
  @CombineSubscriptions()
  subscriptions: Unsubscribable;
  @Input() notificPanel;

  public egretThemes;
  public layoutConf: any;
  public name = 'Sem nome';
  personId = '';

  fxLayout = 'space-between center';

  constructor(
    private router: Router,
    private routerParamsService: RouterParamsService,
    private themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService,
  ) {
    this.subscriptions = routerParamsService.params.subscribe(params => {
      this.personId = params.personId;
    });

    if (!this.personId) {
      this.fxLayout = 'end center';
    }

    const { personProperties } = jwtAuth.getUser();
    if (personProperties) {
      const { name } = personProperties;
      this.name = name;
    }
  }

  ngOnInit() {
    this.egretThemes = this.themeService.egretThemes;
    this.layoutConf = this.layout.layoutConf;
  }

  ngOnDestroy() {}

  linkBack() {
    this.router.navigateByUrl('/pessoas');
  }
}
