import { Component, Inject } from '@angular/core';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageCropConfig } from '../../services/image-crop.service';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'shared-image-crop-dialog',
  templateUrl: './shared-image-crop-dialog.component.html',
  styleUrl: './shared-image-crop-dialog.component.scss',
})
export class SharedImageCropDialogComponent {
  imageChangedEvent: any;
  croppedImage: any = '';
  imageCroped: any = '';
  config: ImageCropConfig;
  originalImage: any;

  constructor(
    private utilsService: UtilsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<SharedImageCropDialogComponent>,
  ) {
    const { config, image } = data;
    this.config = config;
    this.originalImage = image;
    this.fileChangeEvent(image);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.imageCroped = event;
  }

  save() {
    const formData = new FormData();
    formData.append('file', this.imageCroped.blob);
    formData.append('filename', this.originalImage.name);
    this.dialogRef.close(this.imageCroped.blob);
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }
}
