<h2 mat-dialog-title>Ajustar image</h2>
<mat-dialog-content>
  <div class="image-croped">
    <image-cropper
      [imageFile]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="config.aspectRatio"
      [resizeToWidth]="config.size"
      [cropperMinWidth]="config.cropperMinWidth"
      format="png"
      (imageCropped)="imageCropped($event)"
      (imageLoaded)="imageLoaded($event)"
      (cropperReady)="cropperReady()"
      (loadImageFailed)="loadImageFailed()"
    ></image-cropper>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button color="accent" mat-dialog-close>Cancelar</button>
  <button mat-raised-button color="primary" (click)="save()" cdkFocusInitial>Salvar</button>
</mat-dialog-actions>
