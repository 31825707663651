import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { UtilsService } from '../../../../shared/services/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { AutoUnsubscribe } from '../../../../shared/decorators/auto-unsubscribe.decorator';
import { MedicalDocumentsEntityService } from '../../../../state/entity-services/medical-documents-entity.service';
import { noop, tap } from 'rxjs';

@Component({
  selector: 'person-medical-documents-form',
  templateUrl: './person-medical-documents-form.component.html',
})
export class PersonMedicalDocumentsFormComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  formConfig: any;
  values: any = {};
  loading: boolean;

  constructor(
    private medicalDocumentsEntityService: MedicalDocumentsEntityService,
    private utilsService: UtilsService,
    private dialogRef: MatDialogRef<PersonMedicalDocumentsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.setForm();
  }

  ngOnInit(): void {}

  setForm() {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};
    this.formConfig = {
      form,
      options,
      fields: [
        {
          className: 'section-label',
          template: '<label>Código BirdId</label>',
        },
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'column',
          },
          fieldGroup: [
            {
              type: 'input',
              key: 'birdIdCode',
              props: {
                required: true,
                label: 'Código necessário para realizar esta operação',
              },
            },
          ],
        },
      ],
    };
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (this.progressBar) {
      this.progressBar.mode = loading ? 'indeterminate' : 'determinate';
    }
  }

  submit(): void {
    const { form } = this.formConfig;
    if (form.status === 'VALID') {
      this.medicalDocumentsEntityService
        .configureBirdId(this.values.birdIdCode)
        .pipe(
          tap(response => {
            this.dialogRef.close();
            this.utilsService.toast(
              'Envie novamente a sua solicitação',
              'success',
            );
          }),
        )
        .subscribe(noop, err => {
          this.utilsService.setErrorToast(err);
        });
    }
  }

  update(update) {
    this.values = update;
  }
}
