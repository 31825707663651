import { Directive, Input, OnInit, ViewContainerRef } from '@angular/core';
import { PersonHeaderComponent } from '../../views/persons/components/person-header/person-header.component';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[dynamicComponentCreate]',
})
export class DynamicComponentDirective implements OnInit {
  componentRef: any;
  @Input() componentName: string;

  constructor(private container: ViewContainerRef) {}

  ngOnInit(): void {
    this.componentRef = this.container.createComponent(
      componentMapper[this.componentName],
    );
  }
}

const componentMapper: any = {
  personHeader: PersonHeaderComponent,
};
