import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormAnswer,
  FormQuestion,
  FormSubmission,
} from '../../../../state/models/form.model';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormAnswersEntityService } from '../../../../state/entity-services/form-answers-entity.service';
import { noop, tap } from 'rxjs';
import { UtilsService } from '../../../../shared/services/utils.service';
import { MatProgressBar } from '@angular/material/progress-bar';

@Component({
  selector: 'person-form-question-form-dialog',
  templateUrl: './person-form-question-form-dialog.component.html',
})
export class PersonFormQuestionFormDialogComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  formConfig: any;
  values: any = {};
  formQuestion: FormQuestion;
  formSubmission: FormSubmission;
  loading: boolean;

  constructor(
    private utilsService: UtilsService,
    private formAnswersEntityService: FormAnswersEntityService,
    private dialogRef: MatDialogRef<PersonFormQuestionFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const { formQuestion, formSubmission } = data;
    if (formQuestion?.id) {
      this.formQuestion = formQuestion;
    }
    this.formSubmission = formSubmission;
    this.setForm();
  }

  ngOnInit(): void {}

  setForm() {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};
    this.formConfig = {
      form,
      options,
      fields: [
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'row',
          },
          fieldGroup: [this.setInputByType(this.formQuestion.type)],
        },
      ],
    };
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (this.progressBar) {
      this.progressBar.mode = loading ? 'indeterminate' : 'determinate';
    }
  }

  submit(): void {
    let body: FormAnswer = this.createBody();
    if (!body) {
      return;
    }
    const { answer } = this.formQuestion;
    let request: any;
    this.setLoading(true);
    if (answer?.id) {
      body = { ...body, id: answer.id };
      request = this.formAnswersEntityService.update(body);
    } else {
      request = this.formAnswersEntityService.add(body);
    }
    request
      .pipe(
        tap(() => {
          this.setLoading(false);
          this.dialogRef.close(true);
        }),
      )
      .subscribe(noop, error => {
        this.setLoading(false);
        const { message = 'Erro ao atualizar resposta' } = error || {};
        this.utilsService.toast(message, 'Ok');
      });
  }

  createBody(): FormAnswer {
    const {
      type,
      formQuestionChoices,
      slug,
      id: formQuestionId,
    } = this.formQuestion;
    const { id: formSubmissionId } = this.formSubmission;

    const bodyPart = {
      formSubmission: {
        id: formSubmissionId,
      },
      formQuestion: {
        id: formQuestionId,
      },
    };

    if (!this.values[slug]) {
      return null;
    }

    if (type === 'CHOICES') {
      const formQuestionChoicesBody = [];
      Object.keys(this.values[slug]).forEach(key => {
        const choice = formQuestionChoices.find(
          fqc => fqc.value === key && !!this.values[slug][key],
        );
        if (choice) {
          formQuestionChoicesBody.push({
            formQuestionChoice: {
              id: choice.id,
            },
          });
        }
      });

      return {
        ...bodyPart,
        formAnswerChoices: formQuestionChoicesBody,
      };
    } else if (type === 'CHOICE') {
      const formQuestionChoice = formQuestionChoices.find(
        fqc => fqc.value === this.values[slug],
      );
      return {
        ...bodyPart,
        formAnswerChoices: [
          {
            formQuestionChoice: {
              id: formQuestionChoice.id,
            },
          },
        ],
      };
    } else if (['NUMBER', 'TEXT'].includes(type)) {
      return {
        ...bodyPart,
        value: this.formatValue(this.values[slug], type),
      };
    }
  }

  formatValue(value, type): string | number {
    if (type === 'NUMBER') {
      return parseFloat(value);
    }
    return value.toString();
  }

  update(update) {
    this.values = update;
  }

  private setInputByType(type: FormQuestion['type']) {
    const { answer, formQuestionChoices, slug, detail } = this.formQuestion;
    const { formAnswerChoices = [] } = answer || {};
    if (type === 'CHOICES') {
      const facMap: any = {};
      formAnswerChoices.forEach(fac => {
        facMap[fac.value] = true;
      });
      if (!!Object.keys(facMap).length) {
        this.values[slug] = facMap;
      }

      return {
        type: 'multicheckbox',
        key: slug,
        templateOptions: {
          options: formQuestionChoices.map(fqc => {
            return {
              key: fqc.value,
              value: fqc.value,
              label: fqc.value,
            };
          }),
        },
      };
    }

    if (type === 'CHOICE') {
      const [formAnswerChoice] = formAnswerChoices;
      if (formAnswerChoice?.formQuestionChoice?.value) {
        this.values[slug] = formAnswerChoice?.formQuestionChoice?.value;
      }
      return {
        type: 'radio',
        key: slug,
        defaultValue: formAnswerChoice?.formQuestionChoice?.value,
        props: {
          class: 'radio-button-form',
          required: true,
          options: formQuestionChoices.map(fqc => {
            return {
              value: fqc.value,
              label: fqc.value,
            };
          }),
        },
      };
    }

    if (type === 'TEXT' || type === 'NUMBER') {
      if (answer?.value) {
        this.values[slug] = answer?.value;
      }
      return {
        type: type === 'TEXT' ? 'input' : 'number',
        key: slug,
        defaultValue: answer?.value,
        props: {
          placeholder: detail,
          required: true,
        },
      };
    }
  }
}
