import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SelfieRecommendationReject } from '../models/selfie-recommendation-reject';
import { SelfieRecommendation } from '../models/selfie';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { RouterParamsService } from '../../shared/services/router-params.service';
import { map } from 'rxjs/operators';
import { BasePermissionsService } from '../entity-management/services/base/base-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class SelfieRecommendationRejectsEntityService extends BaseEntityService<SelfieRecommendationReject> {
  constructor(
    basePermissionsService: BasePermissionsService,
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, {
        singular: 'selfieRecommendationReject',
        plural: 'selfieRecommendationRejects',
        url: 'selfie-recommendation-rejects',
      },
      routerParamsService,
      basePermissionsService.selfieRecommendationReject
    );
    this.nameId = 'selfieRecommendationRejectId';
  }

  save(
    selfieRecommendationToRemove: any,
    selfieRecommendation: any,
    selfieConsolidatedId: number,
    reason: string,
  ): Observable<SelfieRecommendationReject> {
    const selfieRecommendationRejects = this.createManyForms(
      selfieRecommendationToRemove,
      selfieRecommendation,
      selfieConsolidatedId,
      reason,
    );
    if (!!selfieRecommendationRejects[0]?.id) {
      const [body] = selfieRecommendationRejects;
      return this.update(body);
    }
    const body = { selfieRecommendationRejects };
    return this.httpClient
      .post(`${ this.entityUrl }/create-many`, body)
      .pipe(
        map((response: any) => response[this.pluralEntityName] || []),
      );
  }

  createManyForms(
    selfieRecommendationToRemove: any,
    selfieRecommendation: SelfieRecommendation,
    selfieConsolidatedId: number,
    reason: string,
  ): SelfieRecommendationReject[] {
    const body = [];
    if (selfieRecommendation.type === 'EXAM') {
      selfieRecommendationToRemove.forEach(exam => {
        body.push({
          exam: { id: exam.id },
          selfieConsolidated: { id: selfieConsolidatedId },
          reason,
        });
      });
    }
    if (selfieRecommendation.type === 'REFERRAL') {
      selfieRecommendationToRemove.forEach(specialty => {
        body.push({
          specialty: { id: specialty.id },
          selfieConsolidated: { id: selfieConsolidatedId },
          reason,
        });
      });
    }

    return body;
  }

  selfieUnmetRecommendationsRefreshAll(organizationId: number) {
    return this.httpClient
      .post(`${ environment.apiUrl }/selfie-unmet-recommendations/refresh-all`, {
        organization: { id: organizationId },
      });
  }

  selfieUnmetRecommendations(selfieConsolidatedIds: number): Observable<any> {
    return this.httpClient
      .get(`${ environment.apiUrl }/selfie-unmet-recommendations`, {
        params: { selfieConsolidatedIds },
      })
      .pipe(
        map((response: any) => response?.selfieUnmetRecommendationsList || {}),
      );
  }

}
