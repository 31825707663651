<h1 mat-dialog-title>Editar dados do paciente</h1>
<div mat-dialog-content>
  <formly-form
    (modelChange)="update($event)"
    [fields]="formConfig.fields"
    [form]="formConfig.form"
    [model]="values"
    [options]="formConfig.options"
  ></formly-form>
</div>
<div mat-dialog-actions>
  <div fxFill fxLayout="row">
    <button color="accent"
            fxFlex="50"
            mat-dialog-close
            mat-raised-button
            type="button">
      Cancelar
    </button>
    <button (click)="submit()"
            [disabled]="!checkIsValid() || isLoading"
            color="primary"
            fxFlex="50"
            mat-raised-button
            type="button">
      Salvar
    </button>
  </div>
</div>
