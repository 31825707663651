import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { noop, Observable, Unsubscribable } from 'rxjs';
import {
  debounceTime,
  filter,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { MatOptionSelectionChange } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import {
  AutoUnsubscribe,
  CombineSubscriptions,
} from '../../../../shared/decorators/auto-unsubscribe.decorator';
import { AppConfirmService } from '../../../../shared/services/app-confirm/app-confirm.service';
import { UrlService } from '../../../../shared/services/url.service';
import { FindingEntityService } from '../../../../state/entity-services/finding-entity.service';
import { FindingType } from '../../../../state/models/finding';

@Component({
  selector: 'person-finding-type-autocomplete',
  templateUrl: './person-finding-type-autocomplete.component.html',
  styleUrls: ['./person-finding-type-autocomplete.component.scss'],
})
@AutoUnsubscribe()
export class PersonFindingTypeAutocompleteComponent
  implements OnInit, OnDestroy
{
  @CombineSubscriptions()
  subscriptions: Unsubscribable;

  @Output() callback: EventEmitter<FindingType> =
    new EventEmitter<FindingType>();

  searchControl = new FormControl(null, [Validators.required]);

  options: FindingType[] = [];
  filteredOptions: Observable<FindingType[]>;
  hasFind: boolean;

  @ViewChild(MatAutocompleteTrigger) _auto: MatAutocompleteTrigger;

  constructor(
    private router: Router,
    private confirmService: AppConfirmService,
    private route: ActivatedRoute,
    private urlService: UrlService,
    private findingEntityService: FindingEntityService,
  ) {
    this.subscriptions = route.data
      .pipe(
        tap(() => {
          this.filteredOptions = this.searchControl.valueChanges.pipe(
            tap(() => this.callback.emit(null)),
            debounceTime(300),
            startWith(''),
            map(response => {
              if (typeof response !== 'string') {
                return response.findingTypes;
              }
              return response ? response?.trim() : '';
            }),
            filter(value => {
              return typeof value === 'string' && value.length > 0;
            }),
            switchMap(q => {
              return this.findingEntityService.getWithQueryType({ q });
            }),
            map((value: FindingType[]) => {
              this.hasFind = true;
              this.options = value;
              return value;
            }),
          );
        }),
      )
      .subscribe(noop, () => {});
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  onSelection($event: MatOptionSelectionChange, preSelect?) {
    if ($event.isUserInput) {
      const { findingType } = $event?.source?.value || preSelect;
      if (findingType) {
        this.callback.emit(findingType);
      }
      this.searchControl.setValue('', { emitEvent: false });
    }
  }

  displayFn(response): string {
    const { findingType } = response || {};
    if (!findingType) {
      return '';
    }
    const { name } = findingType || {};
    return `${name}`;
  }
}
