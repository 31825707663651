import { Injectable } from '@angular/core';
import { RouterParamsService } from 'app/shared/services/router-params.service';
import { PatientNote } from '../models/patient-note';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { BasePermissionsService } from '../entity-management/services/base/base-permissions.service';

@Injectable({
  providedIn: 'root',
})
export class PatientNotesEntityService extends BaseEntityService<PatientNote> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
    basePermissionsService: BasePermissionsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'patientNote', plural: 'patientNotes', url: 'patient-notes' },
      routerParamsService,
      basePermissionsService.patientNote
    );
    this.nameId = 'patientNoteId';
  }

}
