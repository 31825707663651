<form (ngSubmit)="submit()" *ngIf="!!formConfig?.fields" [formGroup]="formConfig?.form">
  <div mat-dialog-content>
    <mat-progress-bar class="custom-progress" mode="determinate"></mat-progress-bar>
    <h1 class="subtitle" fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="8px">
      <img [src]="formQuestion.imageUrl" height="24px">
      <span style="white-space: pre-line">{{ formQuestion.title }}</span>
    </h1>
    <formly-form
      (modelChange)="update($event)"
      [fields]="formConfig.fields"
      [form]="formConfig.form"
      [model]="values"
      [options]="formConfig.options"

    ></formly-form>
  </div>
  <div mat-dialog-actions>
    <div fxFill fxLayout="row">
      <button color="accent"
              fxFlex="50"
              mat-dialog-close
              mat-raised-button
              type="button">
        Cancelar
      </button>
      <button
        [disabled]="loading"
        color="primary"
        fxFlex="50"
        mat-raised-button
        type="submit">
        Salvar
      </button>
    </div>
  </div>
</form>
