<form *ngIf="!disableAutocomplete" class="autocomplete">
  <mat-form-field appearance="outline" class="width-100">
    <mat-label>Exames</mat-label>
    <input [disabled]="disableAutocomplete" [formControl]="searchControl"
           [matAutocompleteDisabled]="disableAutocomplete"
           [matAutocomplete]="auto"
           aria-label="Exames"
           matInput
           placeholder="Digite um exame:"
           type="text">
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn"
                      autoActiveFirstOption>
      <mat-option (onSelectionChange)="onSelection($event)"
                  *ngFor="let option of filteredOptions | async"
                  [value]="{exam: option}">
        {{option?.name}}
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="!searchControl.hasError('required')">
      Exame obrigatório
    </mat-error>
  </mat-form-field>
</form>
