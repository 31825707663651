import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';

@Injectable({
  providedIn: 'root',
})
export class PersonReferralFormService {
  constructor() {}

  getFormReferral() {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};
    const fields = [
      {
        type: 'flex-layout',
        props: {
          fxLayout: 'column',
        },
        fieldGroup: [
          // {
          //   type: 'file',
          //   key: '',
          //   props: {
          //     label: 'Nome',
          //     required: true,
          //   },
          // },
        ],
      },
    ];
    return {
      form,
      options,
      fields,
    };
  }
}
