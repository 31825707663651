import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UtilsService } from '../../../../shared/services/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { Person } from '../../../../state/models/person';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { DateService } from '../../../../shared/services/date.service';
import { noop, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'person-edit-dialog',
  templateUrl: './person-edit-dialog.component.html',
})
export class PersonEditDialogComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  formConfig: any;
  values: any;
  public person: Person = this.personEntityService.lastPersonWithAge;
  isLoading: boolean;
  private loading: boolean;

  constructor(
    private dateService: DateService,
    private utilsService: UtilsService,
    private personEntityService: PersonEntityService,
    private dialogRef: MatDialogRef<PersonEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const person = this.data;
    this.person = person as Person;
    const document = this.utilsService.maskCpfCnpj(this.person.document);
    const phone = this.utilsService.phoneFormat(this.person.phone);
    let { birthdate } = this.person?.personProperties;
    birthdate = this.dateService.formatPipe(birthdate, 'YYYY-MM-DDTHH:mm:ss');
    this.values = {
      ...this.person,
      ...this.person?.personProperties,
      phone,
      birthdate,
      document,
      id: this.person.id,
    };
    this.setForm();
  }

  ngOnInit(): void {}

  public checkIsValid(): boolean {
    return !!this.person && this.formConfig.form.status === 'VALID';
  }

  submit() {
    const {
      id,
      name,
      email,
      phone: p,
      biologicalSex,
      birthdate: bdate,
    } = this.values;
    const phone = this.utilsService.removePhoneMask(p);
    const birthdate = this.dateService.formatPipe(bdate, 'YYYY-MM-DD');
    this.setLoading(true);
    this.personEntityService
      .update({
        id,
        email: email ? email : null,
        phone: phone ? `+55${phone}` : null,
        personProperties: { name, biologicalSex, birthdate },
      })
      .pipe(
        tap(() => {
          this.setLoading(false);
          this.dialogRef.close(true);
        }),
        catchError(err => {
          this.setLoading(false);
          const { message } = err || {};
          this.utilsService.toast(message || 'Erro desconhecido da api', 'Ok');
          throw err;
        }),
      )
      .subscribe(noop);
  }

  setForm() {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};
    this.formConfig = {
      form,
      options,
      fields: [
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'column',
          },
          fieldGroup: [
            {
              type: 'input',
              key: 'id',
              defaultValue: '',
              props: {
                disabled: true,
                label: 'Id',
              },
            },
            {
              type: 'input',
              key: 'document',
              defaultValue: '',
              props: {
                disabled: true,
                label: 'CPF',
              },
            },
            {
              type: 'input',
              key: 'name',
              defaultValue: '',
              props: {
                label: 'Nome',
                placeholder: 'Nome e sobrenome',
                required: true,
              },
            },
            {
              type: 'input',
              key: 'email',
              defaultValue: '',
              props: {
                label: 'E-mail',
                placeholder: 'E-mail',
                required: false,
              },
            },
            {
              key: 'biologicalSex',
              type: 'select',
              defaultValue: '',
              props: {
                label: 'Sexo biológico',
                required: true,
                options: [
                  { label: 'Masculino', value: 'MALE' },
                  { label: 'Feminino', value: 'FEMALE' },
                ],
              },
            },
            {
              type: 'datepicker',
              key: 'birthdate',
              defaultValue: '',
              props: {
                label: 'Data de nascimento',
                placeholder: '00/00/0000',
                required: true,
                readonly: true,
              },
            },
            {
              type: 'masked',
              key: 'phone',
              defaultValue: '',
              props: {
                label: 'Telefone',
                placeholder: 'Telefone',
                required: false,
                mask: 'PHONE',
                type: 'tel',
              },
            },
          ],
        },
      ],
    };
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (this.progressBar) {
      this.progressBar.mode = loading ? 'indeterminate' : 'determinate';
    }
  }

  update(update: any) {
    this.values = update;
  }
}
