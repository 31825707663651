import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';

// SERVICES
import { AuthGuard } from './guards/auth.guard';
import { AppConfirmService } from './services/app-confirm/app-confirm.service';
import { AppLoaderService } from './services/app-loader/app-loader.service';
import { RoutePartsService } from './services/route-parts.service';
import { ThemeService } from './services/theme.service';

import { FormlyModule } from '@ngx-formly/core';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { FileUploadComponent } from '../form/components/file-upload/file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CopyLinkPersonSelfieGuard } from './guards/copy-link-person-selfie.guard';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { FlexModule } from '@angular/flex-layout';
import { CdTimerModule } from 'angular-cd-timer';
import { SharedImageCropDialogComponent } from './dialogs/shared-image-crop-dialog/shared-image-crop-dialog.component';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [FileUploadComponent, SharedImageCropDialogComponent],
  imports: [
    MatDialogModule,
    CdTimerModule,
    MatTooltipModule,
    MatIconModule,
    NgxFileDropModule,
    CommonModule,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    FormlyModule,
    MatButtonModule,
    MatProgressBarModule,
    FlexModule,
    ImageCropperComponent,
    NgOptimizedImage,
  ],
  providers: [
    ThemeService,
    RoutePartsService,
    CopyLinkPersonSelfieGuard,
    AuthGuard,
    AppConfirmService,
    AppLoaderService,
  ],
  exports: [
    SharedImageCropDialogComponent,
    FileUploadComponent,
    SharedComponentsModule,
    SharedPipesModule,
    SharedDirectivesModule,
    FormlyModule,
  ],
})
export class SharedModule {
}
