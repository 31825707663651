import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { Person } from '../../../../state/models/person';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { PersonFilesEntityService } from '../../../../state/entity-services/person-files-entity.service';
import { noop, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UtilsService } from '../../../../shared/services/utils.service';

@Component({
  selector: 'person-files-dialog',
  templateUrl: './person-files-dialog.component.html',
})
export class PersonFilesDialogComponent implements OnInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  formConfig: any;
  values: any;
  public person: Person = this.personEntityService.lastPersonWithAge;
  loading: boolean;
  translationOptions: Record<string, string> = {};
  isValid: boolean;

  constructor(
    private utilsService: UtilsService,
    private personFilesEntityService: PersonFilesEntityService,
    private personEntityService: PersonEntityService,
    private dialogRef: MatDialogRef<PersonFilesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const { person, translationOptions, entity } = this.data;
    this.translationOptions = translationOptions;
    this.values = { ...entity, person: { id: person.id } };
  }

  ngOnInit(): void {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};

    this.formConfig = {
      form,
      options,
      fields: [
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'column',
          },
          fieldGroup: [
            {
              key: 'type',
              type: 'select',
              defaultValue: '',
              props: {
                label: 'Tipo',
                required: true,
                options: Object.keys(this.translationOptions).map(key => {
                  return { label: this.translationOptions[key], value: key };
                }),
              },
            },
            {
              type: 'textarea',
              key: 'notes',
              defaultValue: '',
              props: {
                rows: 3,
                placeholder: 'Notas',
              },
            },
          ],
        },
      ],
    };
  }

  public getFileData(file): void {
    this.values = { ...this.values, fileUrl: file.url };
    this.checkIsValid();
  }

  update(update) {
    this.values = { ...this.values, update };
    this.checkIsValid();
  }

  public checkIsValid(): void {
    if (!this.values) {
      this.isValid = false;
    }
    const { type, person, fileUrl, id } = this.values;
    if (id) {
      this.isValid =
        !!type && !!person && this.formConfig?.form?.status !== 'INVALID';
      return;
    }
    this.isValid =
      !!type &&
      !!person &&
      this.formConfig?.form?.status !== 'INVALID' &&
      !!fileUrl;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (this.progressBar) {
      this.progressBar.mode = loading ? 'indeterminate' : 'determinate';
    }
  }

  submit() {
    if (this.isValid) {
      this.setLoading(true);
      const body = {
        ...this.values,
      };
      this.personFilesEntityService
        .save(body)
        .pipe(
          tap(() => {
            this.setLoading(false);
            this.dialogRef.close(true);
          }),
          catchError(err => {
            this.setLoading(false);
            const { message, error } = err || {};
            this.utilsService.toast(
              message || 'Erro desconhecido da api',
              'Ok',
            );
            throw error;
          }),
        )
        .subscribe(noop);
    }
  }
}
