import { Component, Inject, OnInit } from '@angular/core';
import {
  PerformedExamsEntityService,
} from '../../../../state/entity-services/performed-exams-entity.service';
import { noop, tap } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Person } from '../../../../state/models/person';
import { PerformedExamAudFormatted } from '../../../../state/models/exams';

@Component({
  selector: 'person-performed-exam-auds-dialog',
  templateUrl: './person-performed-exam-auds-dialog.component.html',
  styleUrls: ['./person-performed-exam-auds-dialog.component.scss'],
})
export class PersonPerformedExamAudsDialogComponent implements OnInit {
  person: Person;
  auds: PerformedExamAudFormatted[] = [];

  constructor(
    private dialogRef: MatDialogRef<PersonPerformedExamAudsDialogComponent>,
    private performedExamsEntityService: PerformedExamsEntityService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    this.person = data;
    this.performedExamsEntityService
      .auds({ personIds: this.person.id.toString() })
      .pipe(
        tap(response => {
          this.auds = response;
        }),
      )
      .subscribe(noop);
  }

  ngOnInit(): void {}
}
