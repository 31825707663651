<form (ngSubmit)="submit()" [formGroup]="formConfigDefault?.form">

  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutGap="16px">
      <div *ngIf="values?.documentUrl" fxFlex="100">
        <div style="max-height: 700px; overflow-y: auto">
          <div *ngIf="identifyMediaType(values?.documentUrl) === 'pdf'" height="500" id="pdf-content"
               style="max-width: 100%; width: 100%"></div>

          <img *ngIf="identifyMediaType(values?.documentUrl) === 'image'" [src]="values?.documentUrl"
               style="width: 100%;" />
        </div>
      </div>
      <div [fxFlex]="values?.documentUrl?'500px': '100'">
        <mat-progress-bar class="custom-progress" mode="determinate"></mat-progress-bar>
        <formly-form
          (modelChange)="update()"
          [fields]="formConfigDefault.fields"
          [form]="formConfigDefault.form"
          [model]="performedExam ? values: {}"
          [options]="formConfigDefault.options"
          style="margin-top: 10px;display: block;"
        ></formly-form>

        <person-laboratory-autocomplete (callback)="getAutocompleteLaboratory($event)"
                                        style="margin-bottom: 24px;display: block;"
        ></person-laboratory-autocomplete>
        <div>
          <mat-chip-listbox *ngIf="!!values?.laboratory" aria-label="Laboratory">
            <mat-chip>
              {{values?.laboratory?.name}}
              <button (click)="removeLaboratory()" matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-listbox>

        </div>

        <file-upload
          (callback)="getFileData($event)"
          [showRemoveButton]="showRemoveButton"
          prefix="PERFORMED_EXAM"
          style="margin-top: 10px;display: block;"></file-upload>

        <h2 style="margin-top: 10px;display: block;">Input dos exames <span
          *ngIf="performedExam?.exam?.id && !examParseActive">- {{ performedExam?.exam?.name }}</span>
        </h2>

        <person-exams-autocomplete
          (callback)="getAutocomplete($event)"
          [id]="examParseActive ? undefined : performedExam?.exam?.id"
          style="margin-top: 10px;display: block;"
        ></person-exams-autocomplete>

        <mat-tab-group *ngIf="formConfigs.length > 0" [(selectedIndex)]="tabIndex" animationDuration="0ms"
                       dynamicHeight>
          <mat-tab *ngFor="let formConfig of formConfigs">
            <ng-template mat-tab-label>
              {{ formConfig.exam.name }}
              <button (click)="formRemoveByExamId(formConfig.exam.id)" *ngIf="!performedExam || !values?.id"
                      mat-icon-button
                      type="button">
                <mat-icon>close</mat-icon>
              </button>
            </ng-template>
            <div>
              <formly-form
                (modelChange)="update()"
                [fields]="formConfig.fields"
                [form]="formConfig.form"
                [model]="performedExam ? values: {}"
                [options]="formConfig.options"
                style="margin-top: 10px;display: block;"
              ></formly-form>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
  <div mat-dialog-actions>
    <div fxFill fxLayout="row">
      <button color="accent"
              fxFlex="50"
              mat-dialog-close
              mat-raised-button
              type="button">
        Cancelar
      </button>
      <button
        [disabled]="!isValid || loading"
        color="primary"
        fxFlex="50"
        mat-raised-button
        type="submit">
        Salvar
      </button>
    </div>
  </div>
</form>
