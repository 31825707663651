import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MedicalDocuments } from '../models/medical-documents';
import { BaseEntityService } from '../entity-management/services/base/base-entity.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { RouterParamsService } from '../../shared/services/router-params.service';

@Injectable({
  providedIn: 'root',
})
export class MedicalDocumentsEntityService extends BaseEntityService<MedicalDocuments> {

  constructor(
    httpClient: HttpClient,
    routerParamsService: RouterParamsService,
  ) {
    super(
      httpClient,
      environment.apiUrl, { singular: 'medicalDocument', plural: 'medicalDocuments', url: 'medical-documents' },
      routerParamsService
    );
    this.nameId = 'medicalDocumentId';
  }

  configureBirdId(birdIdCode: string): Observable<MedicalDocuments> {
    const body = { medicalDocument: { birdIdCode } };
    return this.httpClient
      .post(`${this.entityUrl}/configure-bird-id`, body)
      .pipe(map((response: any) => response?.medicalDocument));
  }
}
