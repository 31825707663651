import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { DateService } from '../services/date.service';
import { NumeralService } from '../services/numeral.service';

@Pipe({
  name: 'numeralFormat',
})
export class NumeralPipe implements PipeTransform {
  constructor(private numeralService: NumeralService) {}

  transform(value: any, format = '0,0.[0000]'): string {
    if (!value) {
      return '';
    }
    value = parseFloat(value);
    return this.numeralService.format(value, format);
  }
}
