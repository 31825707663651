import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AclService } from '../services/acl.service';

/**
 * Referência: https://juristr.com/blog/2018/02/angular-permission-directive/
 */

@Directive({
  selector: '[permissionControl]',
})
export class PermissionDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private aclService: AclService,
  ) {
  }

  @Input()
  set permissionControl(permissionsName: string[]) {
    this.updateView(permissionsName);
  }

  private updateView(permissionsName: string[]) {
    if (this.aclService.hasPermissions(permissionsName)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
