<h2 mat-dialog-title>Histórico de exames</h2>
<div mat-dialog-content>
  <div *ngFor="let aud of auds">
    <h3 class="text-title">
      <b>{{aud.revisionPersonName}} </b>{{aud.type | stringFormat}} o exame
      <b>{{ aud.examName }}</b> em {{aud.revisionTimestamp}}
    </h3>
    <div fxLayout="row" fxLayoutGap="16px">
      <ng-container *ngIf="aud.type === 'UPDATE'">
        <div fxLayout="column">
          <span *ngIf="!(aud | isEquals: 'documentUrl')" class="line-through"><b>Arquivo:</b> <a
            [href]="aud.old.documentUrl"
            target="_blank">download</a></span>
          <span *ngIf="!(aud | isEquals: 'performedAt')"
                class="line-through"><b>Realizado em:</b> {{aud.old?.performedAt}}</span>
          <span *ngIf="!(aud | isEquals: 'requestedBy')"
                class="line-through"><b>Pedido:</b> {{aud.old?.requestedBy}}</span>
          <span *ngIf="!(aud | isEquals: 'report')" class="line-through"><b>Laudo:</b> {{aud.old?.report}}</span>
          <div fxLayout="column">
            <span *ngFor="let results of aud.old?.results; let i = index">
              <span *ngIf="results !== aud.current.results[i]" class="line-through">- {{ results }}</span>
            </span>
          </div>
        </div>
        <div fxLayout="column">
          <span *ngIf="!(aud | isEquals: 'documentUrl')"><b>Arquivo:</b> <a [href]="aud.current.documentUrl"
                                                                          target="_blank">download</a></span>
          <span *ngIf="!(aud | isEquals: 'performedAt')"><b>Realizado em:</b> {{aud.current.performedAt}}</span>
          <span *ngIf="!(aud | isEquals: 'requestedBy')"><b>Pedido:</b> {{aud.current.requestedBy}}</span>
          <span *ngIf="!(aud | isEquals: 'report')"><b>Laudo:</b> {{aud.current.report}}</span>
          <div fxLayout="column">
            <span *ngFor="let results of aud.current.results; let i = index">
              <span *ngIf="results !== aud.old.results[i]">- {{ results }}</span>
            </span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="aud.type === 'INSERT'">
        <div fxLayout="column">
          <span *ngIf="aud.current?.documentUrl"><b>Arquivo:</b> <a [href]="aud.current.documentUrl"
                                                                    target="_blank">download</a></span>
          <span *ngIf="aud.current?.performedAt"><b>Realizado em:</b> {{aud.current?.performedAt}}</span>
          <span *ngIf="aud.current?.requestedBy"><b>Pedido:</b> {{aud.current?.requestedBy}}</span>
          <span *ngIf="aud.current?.report"><b>Laudo:</b> {{aud.current?.report}}</span>
          <div fxLayout="column">
            <span *ngFor="let results of aud.current?.results; let i = index">
              - {{ results }}
            </span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="aud.type === 'DELETE'">
        <div fxLayout="column">
          <span *ngIf="aud.old?.documentUrl" class="line-through"><b>Arquivo:</b> <a [href]="aud.old.documentUrl"
                                                                                     target="_blank">download</a></span>
          <span *ngIf="aud.old?.performedAt" class="line-through"><b>Realizado em:</b> {{aud.old?.performedAt}}</span>
          <span *ngIf="aud.old?.requestedBy" class="line-through"><b>Pedido:</b> {{aud.old?.requestedBy}}</span>
          <span *ngIf="aud.old?.report" class="line-through"><b>Laudo:</b> {{aud.old?.report}}</span>
          <div fxLayout="column">
            <span *ngFor="let results of aud.old?.results; let i = index">
              <span class="line-through">- {{ results }}</span>
            </span>
          </div>
        </div>
      </ng-container>
    </div>
    <mat-divider></mat-divider>
  </div>
</div>
<div mat-dialog-actions>
  <div fxFill fxLayout="row">
    <button color="accent"
            fxFlex="100"
            mat-dialog-close
            mat-raised-button
            type="button">
      Fechar
    </button>
  </div>
</div>
