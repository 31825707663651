import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { ExamRequest, Exams } from '../../../../state/models/exams';
import { Person } from '../../../../state/models/person';
import { DateService } from '../../../../shared/services/date.service';
import { UtilsService } from '../../../../shared/services/utils.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PersonEntityService } from '../../../../state/entity-services/person-entity.service';
import { noop, tap } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions } from '@ngx-formly/core';
import { ExamRequestsEntityService } from '../../../../state/entity-services/exam-requests-entity.service';
import { FindingType } from '../../../../state/models/finding';
import { PerformedExamsEntityService } from '../../../../state/entity-services/performed-exams-entity.service';
import { PersonDialogsService } from '../../services/person-dialogs.service';

@Component({
  selector: 'person-exam-request-form-dialog',
  templateUrl: './person-exam-request-form-dialog.component.html',
})
export class PersonExamRequestFormDialogComponent
  implements OnInit, AfterViewInit {
  @ViewChild(MatProgressBar) progressBar: MatProgressBar;
  formConfig: any;
  values: any = {};
  examRequest: ExamRequest;
  public person: Person = this.personEntityService.lastPersonWithAge;
  loading: boolean;

  chips: Exams[] = [];

  constructor(
    private personDialogsService: PersonDialogsService,
    private cdr: ChangeDetectorRef,
    private dateService: DateService,
    private utilsService: UtilsService,
    private dialogRef: MatDialogRef<PersonExamRequestFormDialogComponent>,
    private examRequestsEntityService: ExamRequestsEntityService,
    private performedExamsEntityService: PerformedExamsEntityService,
    private personEntityService: PersonEntityService,
    @Inject(MAT_DIALOG_DATA) public data,
  ) {
    const { examRequest, person } = this.data;
    this.examRequest = examRequest;
    this.values = {
      person: { id: person.id },
    };
  }

  ngOnInit(): void {
    const form = new FormGroup({});
    const options: FormlyFormOptions = {};
    this.formConfig = {
      form,
      options,
      fields: [
        {
          type: 'flex-layout',
          props: {
            fxLayout: 'column',
          },
        },
        {
          type: 'textarea',
          key: 'notes',
          defaultValue: '',
          props: {
            rows: 3,
            placeholder: 'Notas',
          },
        },
      ],
    };
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
    this.populate();
  }

  populate(): void {
    if (this.examRequest) {
      const { exams } = this.examRequest;
      const examIds = [];
      exams.forEach(e => examIds.push(e.id));
      if (examIds.length) {
        this.performedExamsEntityService
          .getExams({ ids: examIds.join(',') })
          .subscribe(response => {
            this.chips = response;
          });
      }

      this.values = {
        ...this.values,
        examId: this.examRequest.id,
      };
    }
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (this.progressBar) {
      this.progressBar.mode = loading ? 'indeterminate' : 'determinate';
    }
  }

  submit() {
    if (this.checkIsValid()) {
      this.setLoading(true);
      this.examRequestsEntityService
        .save(this.values, this.chips)
        .pipe(
          tap(() => {
            this.setLoading(false);
            this.dialogRef.close(true);
          }),
          catchError(err => {
            this.setLoading(false);
            const { message, error } = err || {};
            if (error?.code === 56000) {
              this.personDialogsService.openBirdIdDialog();
            }
            this.utilsService.toast(
              message || 'Erro desconhecido da api',
              'Ok',
            );
            throw err;
          }),
        )
        .subscribe(noop);
    }
  }

  public getFileData(file): void {
    this.values = { ...this.values, documentUrl: file.url };
  }

  getAutocomplete(chip: ExamRequest) {
    if (chip && !this.chips.some(ft => ft.id === chip.id)) {
      this.chips.push(chip);
    }
    this.checkIsValid();
  }

  removeChip(findingType: FindingType) {
    this.chips = this.chips.filter(ft => ft.id !== findingType.id);
  }

  public checkIsValid(): boolean {
    if (!this.values) {
      return false;
    }
    const { person } = this.values;
    return (
      this.chips.length > 0 &&
      !!person &&
      this.formConfig?.form?.status === 'VALID'
    );
  }

  update(update) {
    this.values = update;
  }
}
